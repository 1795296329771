import React, {Component} from 'react';

class ElectronicCommunicationModal extends Component {
    componentDidMount(){
        if(typeof window !== undefined){
            document.body.classList.add('no-scroll')
        }
    }
    componentWillUnmount(){
        if(typeof window !== undefined){
            document.body.classList.remove('no-scroll')
        }
    }
    render() {
        return (
            <div>
                <div className="electronic-lb-root  ">
                    <div className="lb-electronic-wrapper">
                    <div className="lb-block lb-table-block  electropersonal">
                        <div className=" table-feature-root lb-electronic-table">
                            <div onClick={()=>this.props.electronicToggle()} className="electronic-close-btn">
                                <div className="close"></div>
                            </div>
                            <div className="lb-electronic-title">
                                <h4>ELEKTRONİK TİCARİ İLETİŞİM İZNİ</h4>
                            </div>
                            <div className="lb-electronic-text-block">
                               <div className="lb-electronic-text">
                                   <p>
                                        İş bu metin HONDA TÜRKİYE A.Ş.  tarafından, ilgili kişinin talebi üzerine, elektronik posta
                                   veya SMS yoluyla, reklam-tanıtım ve kampanyalar içeren e-bültenlerin ilgili kişinin GSM
                                   hattına ve e-posta adresine gönderilmesi hususunda bilgilendirme içermektedir.
                                   </p>
                               </div>
                                  <div className="lb-electronic-text">
                                      <p>
                                           Tarafınıza REKLAM, KAMPANYA, TANITIM İÇERİKLİ elektronik ticari ileti gönderilmesi için
                                      elektronik posta adresiniz ve GSM numaranızdan ( birlikte veya ayrı ayrı olması talebiniz
                                      bağlıdır)  ibaret verinizin işlenmesi gerekmektedir. Bu kapsamda tarafınızdan başkaca
                                      kişisel veri talep edilmemektedir. Detaylar web sitemizdeki Kişisel veri işleme politikamızda
                                      ve aydınlatma metnimizde mevcuttur. E-posta adresinizi ve/veya gsm numaranızı  ilgili alana yazdıktan sonra işbu elektronik ticari iletimine dair açıklamayı kabul ederek gönder tuşuna basmanız durumunda tarafınıza, Honda ve yetkili bayileri tarafından, şirketimize ait kampanyaların, indirimlerin, promosyonların ve ürünlerin tanıtıldığı ticari elektronik iletiler gönderilebilecektir. Dilediğiniz zaman, hiçbir gerekçe belirtmeksizin e-bülten almayı
                                      reddetme hakkına sahipsiniz.
                                      </p>
                                  </div>

                                  <div className="lb-electronic-text">
                                      <p>
                                           İşleminiz tamamlandığında belirttiğiniz cep telefonuna/e-posta adresinize onayınızın
                                      alındığına dair bir ileti göndereceğiz. İstediğiniz her zaman ve hiçbir gerekçe göstermeksizin, gerek söz konusu iletide, gerek yukarıda belirtilen işlemi (ret) yaparak "ticari elektronik
                                      iletişimi" durdurabilirsiniz (kanunlar gereği mümkün veya yapılmakla yükümlü olunan iletişimler devam eder). Ret bildiriminizi kolay ve ücretsiz olarak e- posta- tarafınıza gönderilen SMS sonundaki açıklamaya uygun olarak red beyanı veya tarafınıza gönderilecek e-posta
                                      içerisinde yer alan “Üyelikten ayrılmak istiyorsanız tıklayınız” bağlantısına tıklayarak
                                      yapabilirsiniz. Ret talebinizin şirketimize ulaşmasını müteakip 3 (üç) iş günü içerisinde
                                      elektronik ileti gönderimi durdurulacaktır.
                                      </p>
                                  </div>



                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ElectronicCommunicationModal;