import axios from "axios";
import { DMS_API_TOKEN, PAYZEE_AMOUNT, PAYZEE_USER_CODE } from "../constants/const";
import { WEB_SERVICE_URL, DMS_WEB_SERVICE_URL, PAYZEE_WEB_SERVICE_URL,PAYZEE_MERCHANTID } from "../constants/paths";

export const getVehicles = () => {
    return axios.get(`${WEB_SERVICE_URL}getVehicles`)
}
export const getVehicleDetail = (params) => {
    return axios.post(`${WEB_SERVICE_URL}getVehicleDetail`, "modelLink=" + params)
}

export const getVehicleDealers = (params) => {
    return axios.post(`${WEB_SERVICE_URL}getVehicleDealers`, "modelLink=" + params)
}

export const saveReservation = (params) => {
    return axios.post(`${DMS_WEB_SERVICE_URL}ecommerce-save-reservation?api_token=${DMS_API_TOKEN}&name=${params.name}&surname=${params.surname}&phone=${params.phone_number}&email=${params.email}&address=${params.address}&tckn=${params.tckno}&city=${params.city}&model_id=${params.model_id}&color_id=${params.color_id}&dealer_code=${params.dealer_code}&client_id=${params.client_id}&delivery_type=${params.delivery_type}&recaptcha=${params.recaptcha}&model_img=${params.model_img}`)
}

export const smsVerification = (params) => {
    return axios.post(`${DMS_WEB_SERVICE_URL}ecommerce-sms-verification?api_token=${DMS_API_TOKEN}&verification_code=${params.verification_code}&transaction_id=${params.transaction_id}`)
}

export const resendVerification=(params)=>{
    return axios.post(`${DMS_WEB_SERVICE_URL}ecommerce-send-verification?api_token=${DMS_API_TOKEN}&transaction_id=${params.transaction_id}`)
}
export const getUserPaymentToken = () => {
    return axios.get(`${WEB_SERVICE_URL}getUserPaymentToken`)
}

export const lockVehicle = (transaction_id) => {
    return axios.post(`${DMS_WEB_SERVICE_URL}ecommerce-lock-vehicle?api_token=${DMS_API_TOKEN}&transaction_id=${transaction_id}`)
}


export const releaseVehicle = (transaction_id) => {
    return axios.post(`${DMS_WEB_SERVICE_URL}ecommerce-release-vehicle?api_token=${DMS_API_TOKEN}&transaction_id=${transaction_id}`)
}

export const saveTransactionInfo = (data,transactionId) => {
    return axios.post(`${WEB_SERVICE_URL}saveTransactionInfo`,{transactionObj:data,transactionId})
}

export const getTransactionInfo  = (transactionId) => {
    return axios.post(`${WEB_SERVICE_URL}getTransactionInfo`,{transactionId})
}

export const Payment3d = (params) => {
    return axios.post(`${PAYZEE_WEB_SERVICE_URL}/api/ppg/Payment/Payment3d`,
    {
        "memberId":1,
        "insertCard": false,
        "use3D": true,
        "merchantId": PAYZEE_MERCHANTID,
        "customerId": "123",
        "cardNumber": params.cardNumber,
        "expiryDateMonth": params.expiryDateMonth,
        "expiryDateYear": params.expiryDateYear,
        "cvv": params.cvv,
        "cardAlias": null,
        "userCode": PAYZEE_USER_CODE,
        "txnType": "PreAuth",
        "installmentCount": 1,
        "currency": "949",
        "okUrl": params.okUrl,
        "failUrl": params.failUrl,
        "orderId": params.orderId,
        "totalAmount": PAYZEE_AMOUNT,
        "pointAmount": null,
        "rnd": params.rnd,
        "hash": params.hash,
        "description": params.description,
        "requestIp": null,
        "cardHolderName": params.cardHolderName,
        "extraData": params.extraData,
        "campaign": {
            "text": null,
            "value": null
        },
        "billingInfo": {
            "taxNo": null,
            "taxOffice": null,
            "firmName": null,
            "identityNumber": params.identityNumber,
            "fullName": params.fullName,
            "email": params.email,
            "phone": params.phone,
            "address": params.address,
            "city": params.city,
            "town": params.town,
            "zipCode": null
        },
        "deliveryInfo": {
            "taxNo": null,
            "taxOffice": null,
            "firmName": null,
            "identityNumber": null,
            "fullName": null,
            "email": null,
            "phone": null,
            "address": null,
            "city": null,
            "town": null,
            "zipCode": null
        },
        "orderProductList": null,
        "maturityPeriod": null,
        "paymentFrequency": null
    },
    {
        headers: {
            Authorization: `bearer ${params.token}`
        }
    }
    )
}

export const getTransactionDetail = (transactionId) => {
    return axios.post(`${WEB_SERVICE_URL}getTransactionDetail`,{transactionId})
}

export const getDealerTransactionDetail = (transactionId) => {
    return axios.get(`${DMS_WEB_SERVICE_URL}ecommerce-get-transaction?api_token=${DMS_API_TOKEN}&transaction_id=${transactionId}`)
}

//https://honda.dealercom.net/api/rest/v1/ecommerce-get-transaction-pdf?api_token=cDoZnaXTrpBVHxtYmDJtVtnW&transaction_id=

export const getDealerTransactionPDF = (transactionId) => {
    return axios(`${DMS_WEB_SERVICE_URL}ecommerce-get-transaction-pdf?api_token=${DMS_API_TOKEN}&transaction_id=${transactionId}`,{
        method: 'GET',
        responseType: 'blob' //Force to receive data in a Blob Format
    })
}