import React, {Component} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from '../components/common/loaderComponent';
import ModelItemComponent from '../components/selectCar/modelItemComponent';
import PageService from "../pageServices/pageService";
import {getBreadCrumbLink} from "../actions";
import {breadcrumbArr} from "../constants/const";
import LazyLoad from "react-lazyload";
import {ASSETS} from "../constants/paths";
import {changeDocumentMetas} from "../helper";



class SelectModelPage extends Component {
   titleValue="Online Sıfır Araç Satın Al | Honda Online";
   titleDesc="Honda’nıza kavuşmanın en hızlı ve kolay yolu, Honda Online. Modelinizi seçin, ön ödemenizi gerçekleştirin ve Honda’nızı ister bayiden ister kapınızdan teslim alın.";
   pageService;
   constructor(props) {
      super(props);
      this.pageService = new PageService(props.location.pathname);
      if(!props.models || props.models.length===0){
         this.pageService.getData(this.props.dispatch);
      }
      this.setBreadCrumb()
      changeDocumentMetas(this.titleValue,this.titleDesc)
   }
   setBreadCrumb = () => {
      let modelBreadcrumb = [...breadcrumbArr]
      modelBreadcrumb.forEach((item)=>{
         item.link = null;
         // item.url = null;
         item.isActive = false;
      })
      modelBreadcrumb[0].isActive=true
      /*modelBreadcrumb[0].isActive=true
      modelBreadcrumb[4].link=null
      modelBreadcrumb[1].isActive=false
      modelBreadcrumb[5].isActive=false*/
      this.props.dispatch(getBreadCrumbLink(modelBreadcrumb))
   }


   componentDidMount() {
      if (typeof window !== undefined && this.props.models) {


      }

   }
   componentDidUpdate(prevProps) {
      if (!prevProps.models && prevProps.models !== this.props.models) {

      }
   }

   render() {
      let {models} = this.props;
      
      return (

          <div className="model-list-root">
             <div className="double-content-wrapper">
                <div className="double-content-container ">
                   <div className="content-block">
                      <div className="image-block">
                         <LazyLoad>
                            <picture>
                               <source
                                   media="(min-width: 641px)"
                                   srcSet={`${ASSETS + "/img/z-rv/model-sec-desktop.webp"} 1x, ${ASSETS + "/img/z-rv/model-sec-desktop_2x.webp"} 2x `}
                                   type="image/webp">
                               </source>
                               <source
                                   media="(min-width: 641px)"
                                   srcSet={`${ASSETS + "/img/z-rv/model-sec-desktop.jpg"} 1x, ${ASSETS + "/img/z-rv/model-sec-desktop_2x.jpg"} 2x `}
                                   type="image/png">
                               </source>
                               <source
                                   media="(max-width: 640px)"
                                   srcSet={`${ASSETS + "/img/z-rv/model-sec-mobile.webp"} 1x, ${ASSETS + "/img/z-rv/model-sec-mobile_2x.webp"} 2x `}
                                   type="image/webp">
                               </source>
                               <source
                                   media="(max-width: 640px)"
                                   srcSet={`${ASSETS + "/img/z-rv/model-sec-mobile.jpg"} 1x, ${ASSETS + "/img/z-rv/model-sec-mobile_2x.jpg"} 2x `}
                                   type="image/png">
                               </source>

                               <img src={ASSETS + "/img/z-rv/model-sec-desktop.jpg"} alt="Honda modelinizi seçin"/>
                            </picture>
                         </LazyLoad>
                      </div>
                      <div className="text-block">
                         <div className="number-block">
                            <span>1</span>
                         </div>
                         <div className="explain-block">
                            <p>Honda modelinizi seçin.</p>
                         </div>
                      </div>
                   </div>
                   <div className="content-block">
                      <div className="image-block">
                         <LazyLoad>
                            <picture>
                               <source
                                   media="(min-width: 641px)"
                                   srcSet={`${ASSETS + "/img/z-rv/on-odeme-desktop.webp"} 1x, ${ASSETS + "/img/z-rv/on-odeme-desktop_2x.webp"} 2x `}
                                   type="image/webp"/>
                               <source
                                   media="(min-width: 641px)"
                                   srcSet={`${ASSETS + "/img/z-rv/on-odeme-desktop.jpg"} 1x, ${ASSETS + "/img/z-rv/on-odeme-desktop_2x.jpg"} 2x `}
                                   type="image/jpeg"/>
                               <source
                                   media="(max-width: 640px)"
                                   srcSet={`${ASSETS + "/img/z-rv/on-odeme-mobile.webp"} 1x, ${ASSETS + "/img/z-rv/on-odeme-mobile_2x.webp"} 2x `}
                                   type="image/webp">
                               </source>
                               <source
                                   media="(max-width: 640px)"
                                   srcSet={`${ASSETS + "/img/z-rv/on-odeme-mobile.jpg"} 1x, ${ASSETS + "/img/z-rv/on-odeme-mobile_2x.jpg"} 2x `}
                                   type="image/jpeg">
                               </source>
                               <img src={ASSETS + "/img/z-rv/on-odeme-desktop.jpg"} alt="Ön ödemenizi yapın"/>
                            </picture>
                         </LazyLoad>
                      </div>
                      <div className="text-block">
                         <div className="number-block">
                            <span>2</span>
                         </div>
                         <div className="explain-block">
                            <p>Rezervasyon için ön ödemenizi yapın.</p>
                         </div>
                      </div>
                   </div>
                   <div className="content-block">
                      <div className="image-block">
                         <LazyLoad>
                            <picture>
                               <source
                                   media="(min-width: 641px)"
                                   srcSet={`${ASSETS + "/img/model-page-3.webp"} 1x, ${ASSETS + "/img/model-page-3_2x.webp"} 2x `}
                                   type="image/webp"/>
                               <source
                                   media="(min-width: 641px)"
                                   srcSet={`${ASSETS + "/img/model-page-3.png"} 1x, ${ASSETS + "/img/model-page-3_2x.png"} 2x `}
                                   type="image/jpeg"/>
                               <source
                                   media="(max-width: 640px)"
                                   srcSet={`${ASSETS + "/img/model-page-3-mobile.webp"} 1x, ${ASSETS + "/img/model-page-3-mobile_2x.webp"} 2x `}
                                   type="image/webp">
                               </source>
                               <source
                                   media="(max-width: 640px)"
                                   srcSet={`${ASSETS + "/img/model-page-3-mobile.png"} 1x, ${ASSETS + "/img/model-page-3-mobile_2x.png"} 2x `}
                                   type="image/png">
                               </source>
                               <img className="lazy" src={ASSETS + "/img/model-page-3.png"} alt="Kapınıza teslim edelim."/>
                            </picture>
                         </LazyLoad>
                      </div>
                      <div className="text-block">
                         <div className="number-block">
                            <span>3</span>
                         </div>
                         <div className="explain-block">
                            <p>Bayimiz sizinle iletişim kurduktan sonra seçtiğiniz bayiden aracınızı teslim alın.</p>

                         </div>
                      </div>
                   </div>
                </div>
                <div className="bottom-text-block">
                   <p>BURADAN BAŞLA</p>
                   <div className="icon-item"/>
                </div>
             </div>
             {
                models ?
                    <div className="models-container">
                       {
                          models.length>0 ?
                              <div className="content-container safe-area">
                                 <div className="head-item">
                                    <h1>Modelinizi seçin</h1>
                                 </div>
                                 <div className="model-list-block">
                                    {
                                       models.map((modelItem, key) =>
                                           <ModelItemComponent  {...modelItem} key={key}/>
                                       )
                                    }
                                 </div>
                              </div>
                              :
                              <div className="model-no-stock-block safe-area">
                                 <div className="text-item">
                                    <p>Online stoklarımız güncellenmektedir.</p>
                                    <p>Dilerseniz size en yakın bayimizi ziyaret edebilirsiniz.</p>
                                 </div>
                                 <div className="loader-block">
                                    <div className="bar-item"/>
                                 </div>
                                 <div className="btn-block">
                                    <a href="https://www.honda.com.tr/otomobil/sizi-arayalim" target="_blank" rel="noreferrer" className="stroke-btn">FORMU DOLDURUN</a>
                                 </div>
                              </div>
                       }
                    </div>
                    :
                    <LoaderComponent/>
             }



          </div>

      );
   }
}


const mapStateToProps = (state) => ({
   models: state.modelsReducer.models
})

export default connect(mapStateToProps)(SelectModelPage);
