import React, {Component} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTCHA_SITE_KEY} from "../../constants/const";

class RecaptchaFieldComponent extends Component {
   recaptchaRef=React.createRef();

   onChange = (value) => {
      this.props.input.onChange(value)
   }
   render() {
      let{meta:{touched,error},input}=this.props;
      return (
         <div className={`recaptcha-wrapper ${(touched && error) ? "error":""}`} data-name={input.name}>
            <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={this.onChange}
            />
         </div>
      );
   }
}

export default RecaptchaFieldComponent;
