import React, {Component} from 'react';
import {changeDocumentMetas} from "../helper";


class KvkkComponent extends Component {
    titleValue="KVKK Bilgilendirme Metni | Honda Online";
    titleDesc="Uygulamalarımız ve sahip olduğunuz haklar ile ilgili bilgi almak için Kişisel Verilerin Korunması Kanunu bilgilendirme metnini okuyabilirsiniz.";
    constructor() {
        super();
        changeDocumentMetas(this.titleValue,this.titleDesc)
    }

    render() {
        return (
            <div>
                <div className="legal-warning-root home-root kvkk-root">
                    <div className="title-container">
                        <h1>KİŞİSEL VERİLERİN KORUNMASI KANUNU ÇERÇEVESİNDE BİLGİLENDİRME METNİ</h1>
                    </div>
                    <div className="legal-warning-container">
                        <div className="legal-warning-block">
                            <p>Değerli Müşterilerimiz ve Ziyaretçilerimiz; Honda Türkiye A.Ş. (“Honda”, “Veri Sorumlusu”) olarak 6698 sayılı “Kişisel Verilerin Korunması Kanunu” kapsamında, uygulamalarımız ve haklarınız ile ilgili sizleri bilgilendirmek isteriz.</p>
                            <h2>1-İşlenen Kişisel Veriler, İşlenme Amaçları ve Hukuki Sebepleri</h2>
                            <p>Tarafınıza ait kişisel bilgiler, web sitemizi ziyaretiniz, web sitemiz üzerinden imzaladığınız araç rezervasyon sözleşmeleri, web sitemizden oluşturduğunuz randevular ve doldurduğunuz formlar, telefon veya e-posta yazışmalarındaki paylaşımlarınız çerçevesinde elektronik ortamda, test sürüşleri ve servis randevuları, fuarlar ve diğer aktivitelerde doldurduğunuz formlar, üyelikleriniz, Şirketimizden, bayilerimiz veya yetkili servislerimizden ürün veya hizmet satın almanız, iş yerimizi ziyaretiniz çerçevesinde ise fiziksel ortamlarda doğrudan sizlerden toplanmaktadır. Honda’dan mal veya hizmet satın almanız veya bir başka surette ticari veya hukuki ilişkiye girmeniz durumunda ve ilişkinin mahiyetine göre bu verilerinizden, kimlik bilgileri (Ad, Soyad, TC Kimlik Numarası, Cinsiyet, Doğum Tarihi,(cinsiyet ve doğum tarihi zorunlu değildir) ) başta olmak üzere, iletişim verileriniz (e-mail adresi, adres ve telefon bilgileri,), Honda faaliyet alanı kapsamında araç, plaka, şasi ve motor numarası bilgileri, ruhsat bilgileri, ödeme bilgileri ve iş yerlerimizdeki güvenlik kameralarına yansıyan görsel, çağrı merkezi görüşmelerinde kaydedilen işitsel verileriniz, Honda tarafından, 6698 sayılı Kanun’un 5. Maddesi 2. Fıkrası uyarınca, sürece bağlı olarak, bir sözleşmenin kurulması ve ifası, yasal yükümlülüklerin yerine getirilmesi, bir hakkın tesisi ve haklarınıza zarar vermemek kaydı ile meşru menfaatlerimiz, hukuki gerekçeleri (birlikte veya ayrı ayrı) kapsamında işlenebilecektir. Şirketimizle ilişkinizin türüne göre bu verilerin biri-bir kaçı veya tümü işlenebilir.</p>
                            <p>Yukarıda belirtilen kişisel verileriniz, mal / hizmet satış süreçlerinin, satış sonrası destek hizmetlerinin, üretim ve operasyon süreçlerinin yürütülmesi, finans ve muhasebe işlerinin yürütülmesi, firma / ürün / hizmetlere bağlılık süreçlerinin yürütülmesi, müşteri ilişkileri yönetimi süreçlerinin yürütülmesi, müşteri memnuniyetine yönelik aktivitelerin yürütülmesi, pazarlama analiz çalışmalarının yürütülmesi, reklam / kampanya / promosyon süreçlerinin yürütülmesi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi, talep / şikayetlerin takibi, faaliyetlerin mevzuata uygun yürütülmesi, yasal yükümlülüklerin yerine getirilmesi, iletişim ve bilgi güvenliği faaliyetlerinin yürütülmesi amaçlarıyla işlenmektedir.</p>
                            <h2>2-Kişisel Verilerinizin İşlenmesinde Açık Rıza ve Elektronik Ticari İletişim İzni</h2>
                            <p>Yine Honda tarafından sunulan ürün ve hizmet çeşitliliğimizin geliştirilebilmesi ve sizin için “en iyi hizmet en iyi ürün” prensibi ile hizmet sağlanabilmesi amacıyla eğitim verisi, meslek verisi, Honda bayi ve yetkili servisleri tarafından sunulan her türlü ürün ve hizmete ilişkin kullanım alışkanlığı, tercih, zevk ve beğenilerinizi ifade eden kişisel verileriniz ise, ancak açık rızanız olması halinde işlenebilecektir. Bu bilgileri verme zorunluluğunuz bulunmamaktadır.</p>
                            <p>Yukarıda ifade edilen ve Kanun’un 5/2. Maddesi’ ndeki veri işleme gerekçelerinden birine girmeyen kişisel verileriniz için yasal zorunluluk gereği izninizi talep edeceğimiz durumlarda, web sitemizde kişisel verilerinizin talep edildiği alanlarda veya elektronik formlarda yer alan izin kutucuklarının işaretlenmesi ve onaylanması suretiyle, bayilerimizde ve yetkili servislerimizde ise uygulaması varsa yazılı imzalı izin ile birlikte aynı hukuki nitelik ve değerde olmak üzere elektronik ortamda da izin verebilirsiniz. </p>
                            <p>Honda tarafından sizlere yönelik sağlanacak ürün ve hizmet avantajlarından yaralanma ve haberdar olma iradenizi ortaya koyarak tarafınızla reklam, satış, pazarlama, anket ve benzer amaçlı her türlü ticari elektronik iletişim yapılması amacıyla da ayrıca ticari elektronik iletişim izniniz talep edilmektedir. Elektronik ortamda bilginize sunulan işbu elektronik iletişim izni ve kişisel verilerin işlenmesine dair aydınlatma metnini değerlendirdikten sonra uygun bulmuş iseniz, üyelik veya ilgili elektronik formun altında bulunan onay kutucuklarını ayrı ayrı işaretleyerek “gönder” tuşuna basmanız durumunda izin/onay işleminiz tamamlanmış olacaktır. İzinlerinizi geri almak için her zaman Honda’ya başvurabilirsiniz.</p>

                        </div>
                        <div className="legal-warning-block">
                            <h2>3-Kişisel Verilerinizin Paylaşımı</h2>
                            <p>Honda tarafından işlenen kişisel verileriniz, yukarıda sayılan veri işleme amaçlarıyla aynı çerçevede ve sizlere sağlanan ürün ve hizmetlerin yönetimi için; mevzuatta belirlenen güvenlik ve gizlilik esasları uyarınca yeterli ve etkili önlemler alınmak kaydıyla, ürün veya hizmet aldığınız yetkili bayi ve yetkili servisler ve iş ortakları, tedarikçiler, servis sağlayıcıları ile bu verileri talep etmeye yetkili olan kamu kurum veya kuruluşları ile paylaşılabilecektir.</p>
                            <h2>4-Kanun Kapsamındaki Haklarınız</h2>

                            <p>Honda Türkiye A.Ş. kurumsal websitelesindeki içerik, görsel, video ve grafiklerin, forum, blog, sosyal paylaşım, hayran kulübü sitelerinde ve kişisel sitelerde yer verilmesi ve paylaşılması Honda, son zamanlarda yaygın bir şekilde kullanılan sosyal ağlarda içerik ve görsel paylaşımının varlığının ve öneminin farkında olup websitesini paylaşıma dost bir şekilde tasarlamıştır. Ayrıca ziyaretçilerine kolaylık olması için buna elverişli linkler ve formlara yer vermiştir.</p>
                            <p>İlgili Kişiler olarak, haklarınıza ilişkin taleplerinizi <a href="https://www.honda.com.tr/assets/files/HondaBasvuruFormu2020.pdf" target="_blank" rel="noreferrer" >www.honda.com.tr/KVKK_başvuru_formu</a> adresinden kamuoyu ile paylaşılmış olan başvuru formu veya mevzuata uygun benzer bir içeriğe sahip bir belge ile yazılı olarak veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da ilgili kişi tarafından Honda’ya daha önce bildirilen ve Honda’nın sisteminde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle kimliğiniz teyit edilebilir bir biçimde Honda’ya iletmeniz durumunda Honda talebin niteliğine göre talebi en kısa sürede ve en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak işlemin ayrıca bir maliyet gerektirmesi hâlinde, Honda tarafından</p>
                            <p>Kişisel Verileri Koruma Kurulu’nca belirlenen tarifedeki ücret alınacaktır. Bu kapsamda ilgili kişiler;</p>


                            <p>• Kişisel veri işlenip işlenmediğini öğrenme,</p>
                            <p>• Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</p>
                            <p>• Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</p>
                            <p>• Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</p>
                            <p>• Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>
                            <p>• 6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>

                            <p>• İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</p>
                            <p>• Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarına sahiptir.</p>


                            <p>Saygılarımızla</p>
                            <p>Kişisel verilerinizin korunma ve işlenme esasları ile ilgili kapsamlı bilgi için aşağıda linki verilen Kişisel Verilerin Korunması ve İşlenmesi Politikası’nı inceleyebilirsiniz.</p>

                            <p><a href="https://www.honda.com.tr/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi" target="_blank" rel="noreferrer">www.honda.com.tr/KVKK_Politika_Dökümanı</a></p>
                            <p>İletişim ve başvuru için:</p>
                            <p><a href="https://www.honda.com.tr/" target="_blank" rel="noreferrer">www.honda.com.tr</a>, <a
                                href="mailto:htr.legal@honda-eu.com">htr.legal@honda-eu.com,</a> <a href="https://www.honda.com.tr/assets/files/HondaBasvuruFormu2020.pdf" target="_blank" rel="noreferrer" >www.honda.com.tr/KVKK_başvuru_formu</a></p>
                            <p>Honda TÜRKİYE A.Ş (Veri Sorumlusu)</p>
                            <p>Aydınevler Mah. Sanayi Cad. No:1 34814 Maltepe / İstanbul</p>
                            <p>Mersis No : 0068006505800019</p>


                        </div>
                    </div>

                </div>



            </div>
        );
    }
}

export default KvkkComponent;