import React, {Component} from 'react';


class LegalWarning extends Component {

  

    render() {
        return (
            <div>
                <div className="legal-warning-root home-root">
                    <div className="title-container">
                        <h1>Yasal Uyarı</h1>
                    </div>
                    <div className="legal-warning-container">
                        <div className="legal-warning-block">
                            <p>Honda Türkiye A.Ş. kurumsal websitesinde (www.honda.com.tr) Honda şirket bilgileri, yetkili satıcıları, ürünleri, fiyatları ve kampanyalarıyla ilgili içerik ve görsel sunulmaktadır. Honda Türkiye A.Ş., websitesine ilişkin kuralları ve bilgileri dilediği takdirde, iptal edebilir veya değiştirebilir. Bu hususları takip etmek kullanıcının sorumluluğundadır.</p>
                            <p>Honda Türkiye A.Ş. kurumsal websitesinde sunulan içerik, yalnızca bilgi verme ve tanıtım amaçlıdır. Herhangi bir yasal bağlayıcılığı yoktur. Websitesi ziyaretçilerinden Honda ürünleri satın alması beklenmez. Website içeriği satış teklifi yerine geçmez. Honda Türkiye A.Ş.’ye ait tüm ürünler Honda Yetkili Satıcıları tarafından satılmaktadır. Tüm Honda ürünlerinin satın alımı, o ürüne ait satım sözleşmesine ve bu sözleşmedeki şartlara bağlıdır.</p>
                            <p>Honda Türkiye A.Ş. kurumsal websitesinde yeralan bilgiler olduğu gibi sunulmaktadır. Sunulan görseller ile Türkiye'de satışa sunulan modeller ve özellikleri arasında farklılıklar olabilir. Honda Türkiye A.Ş. sunulan bilgilerin doğruluğunu, yanlış veya eksik bilgi içerip içermediğinin garantisini vermez ve bu gibi oluşabilecek durumlardaki hatalardan sorumlu kabul edilemez. Ayrıca Honda sunulan bilgilerde herhangi bir uyarı yapmaksızın istediği zaman model donanım ve teknik özelliklerinde, aksesuarlarında, renklerinde ve fiyatlarında vs. değişiklik yapma hakkını saklı tutar. Websitesinde sunulan araç fiyat bilgileri yalnızca bilgi amaçlıdır ve Honda tarafından tavsiye edilen perakende satış fiyatıdır. Honda Yetkili Satıcıları dilediği fiyata satmakta özgürdür.</p>
                            <p>Honda Türkiye A.Ş. websitesine internet üzerinden başka websitelerinden gelen bağlantılar ve linklerden ve o websitelerinin içeriğinden sorumlu tutulamaz. Honda Türkiye A.Ş. kurumsal websitesine gelirken kullanılan bağlantılar ve linklerin yer aldığı hiçbir internet sitesi hakkında görüş, bilgi veya destek sunmamaktadır.</p>
                            <p>Aynı şekilde Honda Türkiye A.Ş., kurumsal websitesinde yer alan linkler ve bağlantılarla ulaşılan websitelerinin içeriği ve sunumundan sorumlu tutulamaz. Her iki durumda da oluşabilecek doğrudan ya da dolaylı hasarlardan da (mali kayıplar, bilgi veya sistem kaybı, işgücü kaybı veya iş faaliyetlerinin kesintiye uğraması vb.) Honda Türkiye A.Ş. sorumlu değildir. Honda websitesine bağlantılı diğer internet sitelerini ziyaret ettiğinizde sorumluluk tamamen size aittir.</p>
                            <p>Honda Türkiye A.Ş.’nin kullanıcılar tarafından Honda websitesine girilen bilgileri incelemekle ilgili bir yükümlülüğü yoktur ve bu tür kullanıcı girdilerine ilişkin hiçbir sorumluluğu yoktur ancak Honda Türkiye A.Ş. zaman zaman bu tür kullanıcı girdilerini denetleme ve mazeret göstermeden siteden kaldırma hakkını saklı tutar.</p>

                        </div>
                        <div className="legal-warning-block">
                            <h2>Sorumlulukların sınırlanması </h2>
                            <p>Honda websitesine erişim ve kullanımı sonucu oluşabilecek gerek donanım gerek yazılım hasar ve zararlarından veya bilgisayar donanımınızı etkileyen virüs saldırılarından sorumlu değildir.</p>
                            <p>Honda Türkiye A.Ş. kurumsal websitelesindeki içerik, görsel, video ve grafiklerin, forum, blog, sosyal paylaşım, hayran kulübü sitelerinde ve kişisel sitelerde yer verilmesi ve paylaşılması Honda, son zamanlarda yaygın bir şekilde kullanılan sosyal ağlarda içerik ve görsel paylaşımının varlığının ve öneminin farkında olup websitesini paylaşıma dost bir şekilde tasarlamıştır. Ayrıca ziyaretçilerine kolaylık olması için buna elverişli linkler ve formlara yer vermiştir.</p>
                            <p>Bundan dolayı aşağıdaki paylaşım şartlarına uygunluğu durumunda içeriğinin ve görsellerinin paylaşılmasına izin vermektedir. Ancak Honda Türkiye A.Ş. kurumsal websitesinde yer alan tüm içerik, görseller ve videolar telif hakkına tabi olduğundan önceden Honda Türkiye A.Ş.’ye danışılması rica olunur. Ayrıntılı bilgi için lütfen bize ulaşın formunu doldurunuz.</p>
                            <p>• Websitesinin yasa dışı ve ahlak dışı içeriğinin bulunmaması</p>
                            <p>• Paylaşılan içeriğin (metin, görsel, video, grafik vs) herhangi bir şekilde değiştirilmemesi. Renklerinde, görselin bütünlüğünde oynama yapılmaması, Honda websitesinde yer alan bilgilere ek olarak başka bilgi verilmemesi</p>
                            <p>• İçeriğin Türkçe dilinde kullanılması</p>
                            <p>• Honda Türkiye A.Ş. kurumsal websitesinin görsel tasarımının veya sayfalarının kopyalanmaması</p>
                            <p>• İçeriğin kaynağının belirtilmesi. Doğrudan Honda Türkiye A.Ş. kurumsal websitesinin adresi link olarak yazılabilir.</p>
                            <p>• Telif hakkı belirtilmelidir: Copyright © Honda Motor Co. Ltd. ve Honda Türkiye A.Ş</p>
                            <p>Paylaşımcı içeriğin paylaşılması durumunda yukarıdaki şartları kabul etmiş olur. Ayrıca Honda Türkiye A.Ş.’nin aksi kararında sitesindeki içeriğin kaldırılmasını kabul eder ve işlemi olabilecek en kısa sürede yerine getirmekle sorumludur.</p>
                            <p>Yasal uyarı sayfasının güncellenmesi</p>
                            <p>Honda Türkiye A.Ş., iş gereksinimlerine göre bu yasal uyarıyı istediği zaman güncelleme hakkını saklı tutar ve bundan dolayı siteyi her ziyaretinizde yasal uyarıyı denetlemek için bu sayfayı ziyaret etmeniz gerekir.</p>
                            <p>İhtilaflar</p>
                            <p>Oluşabilecek ihtilaflar halinde İstanbul Mahkemeleri yetkilidir.</p>


                        </div>
                    </div>

                </div>



            </div>
        );
    }
}

export default LegalWarning;