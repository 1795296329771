import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getPersonalInfoFormData, isFormDirtyAction} from '../../actions';
import {emptyTransactionIDAction} from '../../actions/personelActions';
import {SELECT_CAR_PAGE} from "../../constants/routePaths";
import { withRouter} from "react-router-dom";


class ChangeLightBoxComponent extends Component {
   lbCon = React.createRef();

   outsideClick = (e) => {
      if (this.lbCon.current && !this.lbCon.current.contains(e.target)) {
         this.props.backward()
      }
   }

   clearForm = () => {
      this.props.backward()
      let isSummaryChangeButton = this.props.breadCrumbLinks.find(x=>x.name==="Özet")
      if (isSummaryChangeButton.isActive) {
         this.props.history.push(SELECT_CAR_PAGE)
      }
      else {
         this.props.history.replace(this.props.backLink)
      }
      // this.props.history.replace(this.props.backLink)

      this.props.dispatch(emptyTransactionIDAction());
      this.props.dispatch(getPersonalInfoFormData(null))
      this.props.dispatch(isFormDirtyAction(false))

   }

   render() {
      let { backward} = this.props
      return (
          <div className="change-lb-root" onClick={this.outsideClick}>
             <div className="lb-container" ref={this.lbCon}>
                <h3>Emin misiniz?</h3>
                <p>Eğer geri giderseniz, bu formda doldurduğunuz tüm bilgiler kaybolacak.</p>
                <div className="btn-block">
                   <button className="stroke-btn" onClick={() => {
                      backward && backward()
                   }}>HAYIR, DEVAM ET
                   </button>
                   {/*{
                      selectedLink ?
                          <Link to={selectedLink.url} className="red-btn-item">
                             EVET, DEĞİŞTİR
                          </Link>
                          :
                          <button onClick={this.clearForm} className="red-btn-item">EVET, DEĞİŞTİR</button>
                   }*/}
                   {
                      <button onClick={this.clearForm} className="red-btn-item">EVET, DEĞİŞTİR</button>
                   }

                </div>
             </div>
          </div>
      );
   }
}

const mapStateToProps = state => ({
   isDirty: state.commonReducer.isDirty,
   breadCrumbLinks: state.commonReducer.breadCrumbLinks,
})
export default withRouter(connect(mapStateToProps)(ChangeLightBoxComponent));
