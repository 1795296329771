import React, {Component} from 'react';

class DeliveryMethodComponent extends Component {
   deliveryMethod='Bayiden Teslim'
   options = [
      "istanbul", "ankara"
   ]
   submit = (values) => {

   }


   render() {
      let {isActive, isListen, toggle} = this.props
      return (
          <div className="delivery-container">
             <div className="title-block">
                <h2>Teslimat Yöntemi</h2>
                <p>Seçeceğiniz Honda bayisinden otomobilinizi teslim alabilirsiniz. </p>
             </div>
             <div className="select-method-block">
                <div className={`method-item ${isActive ? "active" : ""}`}
                     onClick={() => {
                        toggle("dealer")

                     }}
                >
                   <p>Bayiden Teslim </p>
                </div>
                {
                   /*

                   <div className={`method-item ${isListen === "address" ? "active" : ""}`}
                        onClick={() => {
                           toggle("address")
                           // this.setDataLayer('Adrese Teslim')
                        }}>
                      <p>Adrese Teslim</p>


                   </div>

                    */
                }

             </div>
             {/*  {
                    this.state.isActive &&
                    <CustomSelectBoxComponent/>
                }*/}


          </div>
      );
   }
}

export default DeliveryMethodComponent;
