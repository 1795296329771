import React, { Component } from 'react';


class MsgErrorContainer extends Component {
    render() {
        return (
            <div className="error-container">
                <div className="text-item top-text">
                    <span>*</span>
                    <p>Yurt dışı aktarımına izin vermediğiniz için formun gönderimine devam edemiyoruz.</p>
                </div>
                <div className="text-container">
                    <div className="text-item">
                        <p>Aynı amaçla bize ulaşmak için <a href="tel:0850 733 22 20" rel="noreferrer">0850 733 22 20</a> numaralı telefondan, <a href="mailto:bilgi@honda-eu.com">bilgi@honda-eu.com</a> e-posta adresinden ulaşabilirsiniz.   Detay için KVKK Aydınlatma Metni <a href="https://www.honda.com.tr/kisisel-verilerin-korunmasi" rel="noreferrer"
                            target="_blank">www.honda.com.tr/kisisel-verilerin-korunmasi</a> bakınız.</p>
                    </div>
                    <div className="text-item">
                        <p>Veri Sorumlusu: Honda Türkiye A.Ş.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default MsgErrorContainer;