import React, {Component} from 'react';


class CustomSelectBoxComponent extends Component {
   state = {
      isOpen: false,
      selectedItem: null
   }
   clickSpace = React.createRef();
   windowClicked = (e) => {
      if (this.clickSpace.current && !this.clickSpace.current.contains(e.target) && this.state.isOpen) {
         this.setState({
            isOpen: false
         })
      }
   }

   componentDidMount() {
      if (typeof window!=="undefined"){
         window.addEventListener("click", this.windowClicked)

      }

   }

   toggleMenu = () => {
      this.setState({
         isOpen: !this.state.isOpen
      })
   }
   selectedOptions = (selectedItem) => {
      this.setState({
         selectedItem
      })
     this.props.setReducer && this.props.setReducer(selectedItem)
   }
   componentWillUnmount() {
      if (typeof window!=="undefined"){
         window.removeEventListener("click", this.windowClicked)

      }
   }

   render() {
      let {options} = this.props
      return (
          <div className={`detail-block `}
               ref={this.clickSpace}>
             <div className="name-item">
                <p>{""}</p>
             </div>
             {
                options &&
                <div className={`select-block`}>
                   <div className={`select-item ${this.state.isOpen &&  options.length > 1 ? "opened" : ""} `} style={options.length < 2  ? {cursor:"default"} : {}} onClick={() => this.toggleMenu()}>
                      <p>{this.state.selectedItem || options[0]} </p>
                      {
                         options.length > 1 &&
                         <div className="open-select-menu">
                            <div className="open-select-container">
                               {
                                  options && options.map((item, key) =>
                                      <div className="menu-item" key={key} onClick={() => this.selectedOptions(item)}>
                                         <p>{item}</p>
                                      </div>
                                  )
                               }
                            </div>
                         </div>

                      }
                      {
                         options.length > 1 &&
                         <i className="gg-chevron"/>
                      }
                   </div>
                   <div className="error-text">
                      <span>{""}</span>
                   </div>
                </div>
             }

          </div>
      );
   }
}

export default CustomSelectBoxComponent;
