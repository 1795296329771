import React,{Component} from 'react';
import { HOME_PAGE } from '../constants/routePaths';
import { getDealerTransactionPDF } from '../services/webService';

class ContractPage extends Component{
    state = {
        pdfData:null
    }
    componentDidMount(){
        if(typeof window !== "undefined"){

            getDealerTransactionPDF(this.props.match.params.transactionId).then((res)=>{
                if(res.data.type === "application/json"){
                    this.props.history.replace(HOME_PAGE);
                }else if(res.data){
                    const file = new Blob(
                        [res.data], 
                        {type: 'application/pdf'});
                    const fileURL = URL.createObjectURL(file);
                    this.setState({
                        pdfData:fileURL
                    })
                }else{
                    this.props.history.replace(HOME_PAGE);
                }
            }).catch(err=>{
                this.props.history.replace(HOME_PAGE);
                console.log("err",err)
            })
        }
    }
    render(){
        return(
            <embed style={{width:"100%",height:"100vh"}} src={this.state.pdfData} type="application/pdf"></embed>  
        )
    }
};

export default ContractPage;