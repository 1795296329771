import React, {Component} from 'react';
import {ASSETS} from "../constants/paths";
import {Link} from "react-router-dom";
import { SELECT_CAR_PAGE} from "../constants/routePaths";
import {connect} from "react-redux";
import {getBreadCrumbLink} from "../actions";
import {changeDocumentMetas} from "../helper";

class NotFoundPage extends Component {
    titleValue=null;
    titleDesc=null;
    constructor() {
        super();
        changeDocumentMetas(this.titleValue,this.titleDesc)
    }
    componentDidMount() {
        this.props.dispatch(getBreadCrumbLink([]))
    }


    render() {
        return (
            <div className="not-found-root">
                    <div className="not-found-container">
                        <div className="head-block">
                            <h1>SAYFA<br /> BULUNAMADI</h1>

                        </div>
                        <div className="mid-img-block">
                            <img src={`${ASSETS}/img/not-found.png`} alt="404" />
                        </div>
                        <div className="btn-block">
                            <Link to={SELECT_CAR_PAGE} className="red-btn-item">ANA SAYFA</Link>
                        </div>
                    </div>
            </div>
        );
    }
}

export default connect() (NotFoundPage);