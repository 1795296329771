import React, {Component} from 'react';
import {connect} from "react-redux";

class TextInputComponent extends Component {
   changeFunc = (e) => {
      this.props.input.onChange(e)
      this.props.onChangeFunc && this.props.onChangeFunc(e.target.value)
   }


   render() {
      const {input, label, placeholder, meta: {error, touched}, err_txt, maxLength, id} = this.props
      return (
          <div className={`input-item${touched && error ? " error" : ""}`}>
             <label htmlFor="">{label}</label>
             <input  {...input} data-name={input.name} autoFocus={input.name === "verification_code"}
                     onChange={this.changeFunc} placeholder={placeholder} maxLength={maxLength} id={id}/>
             <div className="error-icon">

                <i>
                   <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx={10} cy="10.5" r={9} stroke="#E31F2E" strokeWidth={2}/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M11 15.5H9V13.5H11V15.5ZM11 11.5H9V5.5H11V11.5Z"
                            fill="#E31F2E"/>
                   </svg>
                </i>
             </div>
             <div className="error-text">
                <span>{err_txt}</span>
             </div>
          </div>

      );
   }
}


export default connect()(TextInputComponent);
