import React, {Component} from 'react';
import {Link} from "react-router-dom";
import MsgErrorContainer from "../../containers/msgErrorContainer";
import PersonalDataModal from "../lightBox/personalDataModal";
import ElectronicCommunicationModal from "../lightBox/electronicCommunicationModal";
class CheckBoxFieldComponent extends Component {

    state = {
        isPersonalDataModal: false,
        isElectronicModal:false
    }

    personaDataToggle = () => {
        this.setState({
            isPersonalDataModal: !this.state.isPersonalDataModal
        })
    }

    electronicToggle = () => {
        this.setState({
            isElectronicModal: !this.state.isElectronicModal
        })
    }



   render() {
      const {text, input, meta: {error,submitFailed ,touched},openReservationLb,isPd,isEti,isKvk} = this.props;
      return (
          <div>
              <div className={`radio-button  ${touched && error && submitFailed ? "error" : ""}  `}>
                  <div className={`radio-item  ${touched && error && submitFailed ? "error" : ""}  `}>
                      <input id={input.name} name="radio" {...input} type={input.type}/>
                      <label htmlFor={input.name} className="radio-label">
                          {
                              isKvk ?
                                  <span><a href="https://www.honda.com.tr/kisisel-verilerin-korunmasi" rel="noreferrer" target="_blank" >KVKK aydınlatma metnini</a> okudum.</span>
                                  :
                                  isPd ?
                                      <div>
                                          <span>Kişisel verilerimin <Link to onClick={() => this.personaDataToggle()}>  burada</Link> açıklandığı şekilde Honda tarafından yurt dışına aktarılmasına izin veriyorum.</span>

                                      </div>
                                      :
                                      isEti ?
                                          <span>Honda ve yetkili bayilerinden kampanyalarla ilgili <Link to onClick={() => this.electronicToggle()} >elektronik ticari ileti</Link> (SMS, E-mail, Çağrı, MMS) almak istiyorum.</span>
                                          :
                                          <span dangerouslySetInnerHTML={{__html: text}} onClick={openReservationLb&&openReservationLb}/>
                          }

                      </label>

                  </div>
              </div>
              {
                  submitFailed && input.name === "personalData" && input.value !==true ?
                      <MsgErrorContainer />
                      :
                      <div/>
              }
              {
                  this.state.isPersonalDataModal === true ?
                      <PersonalDataModal personaDataToggle={this.personaDataToggle}/> : <div></div>
              }

              {
                  this.state.isElectronicModal ?
                      <ElectronicCommunicationModal electronicToggle={this.electronicToggle} /> : null
              }
          </div>

      );
   }
}

export default CheckBoxFieldComponent;
