import React, {Component,Fragment} from "react";
import LazyLoad from "react-lazyload";
import {ASSETS} from '../../constants/paths';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {emptyTransactionIDAction} from "../../actions/personelActions";
import ChangeLightBoxComponent from "../lightBox/changeLightBoxComponent";
import {PAYZEE_AMOUNT} from "../../constants/const";


class SummaryButtonComponent extends Component {
   state={
      back:false
   }
   isSummary;
   summeryBlock = React.createRef();


   componentDidMount() {
      /*if (typeof window !== "undefined"){
         window.addEventListener("scroll",this.btnFooterCheck);
         window.addEventListener("resize",this.btnFooterCheck);
         setTimeout(this.btnFooterCheck,200)
      }*/
   }
   componentDidUpdate(prevProps) {
      /*if(prevProps.htmlHeight !== this.props.htmlHeight){
         this.btnFooterCheck()
      }*/
   }

   goBack = () => {
      /*todo:backlink????*/
      this.isSummary = this.props.breadCrumbLinks.find(x=>x.name === "Özet")
      if(this.props.backLink&&!this.props.isDirty){
         this.props.history.replace(this.props.backLink)
      }else{
         this.backward()
      }
      /*todo:dirty*/
      if(this.props.isDirty && this.isSummary.isActive) {
         // console.log("22222222222")
         // this.props.history.goBack();
         this.props.history.replace(this.props.backLink)
      }

      if (this.props.haveToEmpty) {
         this.props.dispatch(emptyTransactionIDAction());
      }
   }
   backward=()=>{
      this.setState({
         back:!this.state.back
      })
   }
   btnFooterCheck = () => {
      // debugger
      if (typeof window !== "undefined"){
         let footerContainer = document.getElementById("footer-block");
         let html = document.documentElement
         let height = html.offsetHeight
         // if (footerContainer &&  height - window.innerHeight - footerContainer.offsetHeight < window.scrollY) {
         //    if (this.summeryBlock.current) {
         //       this.summeryBlock.current.style.bottom = `${((window.scrollY) - (height - window.innerHeight - footerContainer.offsetHeight))}px`
         //    }
         // } else if(footerContainer){
         //    if (this.summeryBlock.current) {
         //       this.summeryBlock.current.style.position = 'fixed'
         //       this.summeryBlock.current.style.bottom=null
         //    }
         // }
      }


   }

   render() {
      let { link, selectedColor, personalInfo,userSelectedColor} = this.props;
      let isStock = userSelectedColor && userSelectedColor.stock
      
      return (

          <div className="bottom-progress-root" ref={this.summeryBlock} style={this.props.isBcOpen ? {zIndex:9}:{zIndex:999}}>
             {
                (this.props.isDirty && this.state.back && !this.isSummary.isActive)&&
                <ChangeLightBoxComponent backLink={this.props.backLink} personalInfo={personalInfo || true} backward={this.backward}/>
             }
             <div className="bottom-progress-container">
                <div className="list-block">
                   {
                      (userSelectedColor) &&
                      <div className="list-item">
                         <div className="img-item">
                             <LazyLoad>
                               {
                                  userSelectedColor && userSelectedColor.detail &&
                                  <picture>
                                     <source
                                         srcSet={`${ASSETS}${userSelectedColor.detail.summary_img.substring(0, userSelectedColor.detail.summary_img.lastIndexOf("."))}.webp 1x, ${ASSETS}${userSelectedColor.detail.summary_img.substring(0, userSelectedColor.detail.summary_img.lastIndexOf("."))}_2x.webp 2x`}/>
                                     <source
                                         srcSet={`${ASSETS}${userSelectedColor.detail.summary_img} 1x, ${ASSETS}${userSelectedColor.detail.summary_img.substring(0, userSelectedColor.detail.summary_img.lastIndexOf("."))}_2x${userSelectedColor.detail.summary_img.substring(userSelectedColor.detail.summary_img.lastIndexOf("."))} 2x`}/>
                                     <img  src={ASSETS + userSelectedColor.detail.summary_img}
                                          alt={userSelectedColor.name}/>
                                  </picture>
                                  
                               }
                            </LazyLoad> 
                         </div>
                         <p>{userSelectedColor.model}</p>

                      </div>
                   }

                   <div className="list-item price">
                      <span>Rezervasyon Bedeli</span>
                      <span>{PAYZEE_AMOUNT.substring(0, 2) + "." + PAYZEE_AMOUNT.substring(2, 5)} TL</span>
                   </div>
                </div>

                <div className="list-item btn-block">
                   <div onClick={() => {
                      this.goBack()
                      // personalInfo && this.destroyPersonalInfoData()
                   }}>
                      <button  type={"button"} className="stroke-btn">GERİ</button>
                   </div>
                   {
                      link ?
                           <Fragment >
                              {
                                 isStock === false ?
                                 <p className="err-txt">STOK BULUNMAMAKTADIR.</p>
                                 :
                                 <Link  className="red-btn-item" to={link} onClick={this.props.buttonDataLayer}>DEVAM</Link>
                              }
                           </Fragment>
                          :
                          <button
                        //   onClick={() =>{
                        //      console.log("111",document.getElementById('personalInfo'));
                        //    document
                        //    .getElementById('personalInfo')
                        //    .dispatchEvent(new Event('submit', { cancelable: true }))
                        //   }
                        //  }
                         type={"submit"} form="personalInfo" className="red-btn-item" onClick={this.props.buttonDataLayer}>DEVAM</button>
                   }
                </div>
             </div>
          </div>
      )
   }
};


const mapStateToProps = (state,ownProps) => ({
   selectedModel: state.personelReducer.selectedModel ? state.personelReducer.selectedModel : {},
   // selectedColor: state.userSelectionReducer.selectedColor,
   selectedColor: state.commonReducer.vehicleDetailData && state.commonReducer.vehicleDetailData[ownProps.modelLink] ? state.commonReducer.vehicleDetailData[ownProps.modelLink].selectedModel : null,
   isDirty:state.commonReducer.isDirty,
   breadCrumbLinks: state.commonReducer.breadCrumbLinks,
   htmlHeight:state.commonReducer.htmlHeight,
   isBcOpen:state.commonReducer.isBcOpen,
   userSelectedColor: state.userSelectionReducer.selectedColor || null,
})

export default connect(mapStateToProps)(SummaryButtonComponent)
