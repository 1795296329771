import{combineReducers} from 'redux';
import commonReducer from "./commonReducer";
import modelsReducer from "./modelsReducer";
import userSelectionReducer from "./userSelectionReducer";
import personelReducer from "./personelReducer";

const combineRed = combineReducers({
    commonReducer,
    modelsReducer,
    userSelectionReducer,
    personelReducer
});

export default combineRed
