import React, {Component} from 'react';
import SummaryVehicleComponent from "../components/summary/summaryVehicleComponent";
import {connect} from 'react-redux';
import {getTransactionInfoAction} from '../actions';
import {PAYZEE_AMOUNT} from "../constants/const";
import {changeDocumentMetas} from "../helper";

class PaymentResultPage extends Component {
   titleValue=null;
   titleDesc=null;
   constructor() {
      super();
      changeDocumentMetas(this.titleValue,this.titleDesc)
   }
   componentDidMount() {
      this.props.dispatch(getTransactionInfoAction(this.props.match.params.transactionId))
      changeDocumentMetas(this.titleValue,this.titleDesc)
      // this.paymentResultDataLayer()



   }




   componentDidUpdate(prevProps) {
      if(!prevProps.selectedModel && typeof window !== "undefined" && prevProps.selectedModel !== this.props.selectedModel){

          //this.paymentResultDataLayer()
      }
   }



   render() {
       console.log(this.props.selectedModel)
      return (
          <div className="payment-result-root">
             <div className="payment-result-wrapper safe-area">
                <SummaryVehicleComponent/>
                <div className="payment-container">
                   <div className="title-block"><h1>Teşekkürler</h1>
                      <p>{PAYZEE_AMOUNT.substring(0, 2) + "." + PAYZEE_AMOUNT.substring(2, 5)} TL Rezervasyon ücreti
                         kartınızdan tahsil edilmiştir.</p>
                   </div>
                   <div className="title-block"><h2>Sırada ne var?</h2>
                      <p>Satış danışmanımız sizinle en kısa sürede iletişime geçecektir. Aracınız teslim edilene kadar her aşamada bilgilendirileceksiniz.</p>
                   </div>
                   <a href="https://honda.com.tr/" target="_blank" rel="noreferrer" className="red-btn-item">honda.com.tr’ye dön</a>

                </div>
             </div>
          </div>
      );
   }
}
const mapStateToProps=(state)=>({
   personalData: state.commonReducer.personalInfoFormData || {},
   selectedModel:state.personelReducer.selectedModel,
   transactionId:state.commonReducer.transactionId || null,
    version_detail: state.userSelectionReducer.version_detail || null,

})
export default connect(mapStateToProps)(PaymentResultPage);
