import {RECEIVE_MODELS,RECEVIVE_DEALERS_BY_MODEL} from "../constants/actionTypes";

export const receiveModels = (payload) => ({
    type: RECEIVE_MODELS,
    payload
});


export const receiveDealersByModel = (payload) => ({
    type:RECEVIVE_DEALERS_BY_MODEL,
    payload
 })