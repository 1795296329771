import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from "moment";

class ReservationLbComponent extends Component {
    lbCon= React.createRef();

    outsideClick=(e)=>{
        if(this.lbCon.current && !this.lbCon.current.contains(e.target)){
            this.props.closeLightBox()
        }
    }
    componentWillUnmount() {
        if (typeof window !== "undefined"){
            document.body.classList.remove('no-scroll');
        }

    }
    render() {
        let {personalData}=this.props
        return (
            <div className="reservation-lb-root" onClick={(e)=>this.outsideClick(e)}>
                <div className="lb-container" ref={this.lbCon}>
                    <div className="close-item" onClick={ this.props.closeLightBox}>
                        <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 1L5 5L9 9" stroke="#777777" strokeWidth={2} strokeLinecap="round" />
                            <path d="M1 1L5 5L1 9" stroke="#777777" strokeWidth={2} strokeLinecap="round" />
                        </svg>
                    </div>
                    <div className="lb-block">
                        <h2 >
                            ARAÇ REZERVASYON SÖZLEŞMESİ
                        </h2>
                        <h2>
                            1. TARAFLAR</h2>
                        <h2>1.1. MÜŞTERİ</h2>
                        {
                            personalData&&
                                <div>
                                    <p>Adı / Soyadı /Ticari Ünvanı : <span>{personalData.name}</span></p>
                                    <p>Telefon No : <span>{personalData.phone}</span></p>
                                    <p>Posta : <span>{personalData.email}</span></p>
                                   {/* <p>Adres : <span>{personalData.address?(personalData.address+" "+personalData.city.label+" "+personalData.district.label):(personalData.dealer_city.label+" - "+personalData.dealer.label)}</span></p>*/}
                                    <p>TCKN :  <span>{personalData.tckn}</span></p>
                                </div>

                        }

                        <p><span>1.2.</span>HONDA TÜRKİYE A.Ş. (Bundan böyle “HONDA” olarak anılacaktır.)</p>
                        <p>Adres : Aydınevler Mah. Sanayi Cad. No: 134854 Maltepe/İstanbul</p>
                        <p>Vergi Dairesi / Vergi Numarası : Yenikapı V.D. /0680065058</p>
                        <p>Telefon No : +908505491010</p>
                        <p>Fax No : +908502127753</p>
                        <p>
                            MERSİS Numarası : 0068006505800019
                        </p>
                        <p>
                            Kep Adresi :    <a href="mailto:honda.finans@hs03.kep.tr">honda.finans@hs03.kep.tr</a>
                        </p>
                        <p>
                            İşbu Araç Rezervasyon Sözleşmesi (“Sözleşme”) yukarıda bilgileri yer alan MÜŞTERİ ve HONDA arasında akdedilmiştir. İşbu Sözleşme kapsamında “MÜŞTERİ” ve “HONDA” münferit kullanıldığında “Taraf”, birlikte kullanıldığında “Taraflar” olarak anılacaklardır.
                        </p>
                        <h2>
                            2.
                            SÖZLEŞMENİN KONUSU
                        </h2>
                        <p>
                            <span>2.1.</span>
                            İşbu Sözleşme, MÜŞTERİ’nin HONDA’ya ait web sitesi olan    <a href="https://www.online.honda.com.tr">online.honda.com.tr</a> üzerinden (“Web Sitesi” olarak anılacaktır), özelliklerini seçmiş olduğu aracın rezervasyon ücretini ödemesi ve HONDA tarafından MÜŞTERİ’nin aracın satış ve teslimi için seçtiği HONDA yetkili bayisinde aracın MÜŞTERİ adına rezerve edilmesi ile tarafların ileride akdedilmesi planlanan araç satış sözleşmesine ilişkin karşılıklı hak ve yükümlülüklerini düzenlemektedir.
                        </p>
                        <p>
                            <span>2.2.</span>
                            İşbu Sözleşme, Web Sitesi üzerinden yapılan yönlendirmeler sonucunda MÜŞTERİ’nin rezervasyon bedelini ödemesi ile Web Sitesi’nin MÜŞTERİ’yi Sözleşme’ye yönlendirdiği sayfa sonunda yer alan “Sözleşme’nin bütün şartlarını okudum, anladım, onaylıyorum” butonunu işaretlemesi sonucunda bütün hüküm ve şartlarıyla elektronik ortamda akdedilmiş kabul edilir. MÜŞTERİ, rezervasyon bedelini ödeyip, Sözleşme’yi onaylayarak, işbu Sözleşme’nin bütün hüküm ve şartlarına ilişkin olarak HONDA tarafından bilgilendirildiğini kabul ve beyan eder.

                        </p>
                        <p>
                            <span>2.3.</span>
                            İşbu Sözleşme, elektronik ortamda yapılmıştır. MÜŞTERİ, işbu Sözleşme’nin araç satış sözleşmesi, mesafeli satış sözleşmesi ya da satış sözleşmesi vaadi niteliğinde olmadığı, işbu Sözleşme’nin akdi ile aracın mülkiyetini kazanmayacağı ve 6502 sayılı Tüketicinin Korunması Hakkında Kanun uyarınca “tüketici” ya da 6098 sayılı Türk Borçlar Kanunu ile 6102 sayılı Türk Ticaret Kanunu uyarınca “alıcı” sıfatlarından birine sahip olmayacağı konusunda bilgilendirildiğini kabul ve beyan eder.

                        </p>
                        <p>
                            <span>2.4.</span>
                            Niteliğinin araç satış sözleşmesi olmaması sebebiyle, işbu Sözleşme’nin akdedilmiş olması ve Sözleşme kapsamında rezervasyon ücretinin ödenmiş olması rezerve edilen aracı mutlak surette satın alacağının MÜŞTERİ’ye garanti edildiği anlamına gelmemektedir.
                        </p>
                        <h2>
                            3.
                            MÜŞTERİNİN HAK VE YÜKÜMLÜLÜKLERİ
                        </h2>
                        <p>
                            <span>3.1.</span>
                            MÜŞTERİ, Web Sitesi üzerinden, özelliklerini seçtiği aracı satın almak için, Web Sitesi’nde belirlenen yöntemlere uygun olarak rezervasyon bedelini ödeyerek rezervasyon yapar. MÜŞTERİ’nin rezervasyon bedelini ödemesi ardından HONDA, MÜŞTERİ tarafından seçilen yetkili bayisinden satın alabilmesi için aracı  3 iş günü MÜŞTERİ adına rezerve eder.
                        </p>
                        <p>
                            <span>3.2.</span>
                            HONDA, rezervasyon bedelini ödemesi üzerine MÜŞTERİ ile yetkili bayileri aracılığıyla iletişime geçip, MÜŞTERİ’nin 3 iş günü içerisinde araç satın alma niyetiyle rezervasyon yaptırdığını teyit etmesinin akabinde, araç satışının yapılması için gerekli bilgi ve belgeleri ileterek MÜŞTERİ ile mutabık kalacaktır. Araç, mutabık kalınan MÜŞTERİ’ye kesin satışının yapılması amacıyla, MÜŞTERİ’nin seçtiği yetkili bayide hazır edilecektir. HONDA, rezervasyon bedelinin ödenmesinden itibaren 3 iş günü sonunda, MÜŞTERİ’nin rezervasyon tarih aralığında aracı satın alıp almadığına, aracı satın almaktan vazgeçmesine ya da vazgeçmiş sayılmasına, rezervasyonun iptal edilmesine bakmaksızın işbu bedeli MÜŞTERİ’ye herhangi bir masraf, komisyon ya da herhangi bir ad altında kesinti yapmaksızın, rezervasyon bedelini aynen iade edecektir.
                        </p>
                        <p>
                            <span>3.3.</span>
                            HONDA, araç rezervasyonunu işbu Sözleşme konusu hizmet için belirlemiş olduğu stoklarıyla sınırlı sayıda sağlar.
                        </p>
                        <p>
                            <span>3.4.</span>
                            MÜŞTERİ, Web Sitesi’nde yayınlanan aracın renklerinde çıplak gözle görünenden kısmen veya tamamen farklılık olabileceğini bildiğini, HONDA’nın ya da yetkili bayilerinin Web Sitesi’nde aracın özellikleri, fiyatı, vergileri ve diğer detaylarında sehven yapılan yazım yanlışlarından ötürü hiçbir şekilde sorumlu olmayacağını kabul etmektedir.
                        </p>
                        <p>
                            <span>3.5.</span>
                            Rezervasyon sırasında belirtilen tavsiye edilen araç fiyatı bilgi amaçlı verilmiş olup, aracın asıl satış fiyatı satışı yapacak ilgili Honda bayisi tarafından teslim tarihinde belirlenecektir. MÜŞTERİ, işbu Sözleşme’nin akdi sonrasında, satış işleminin sağlanması amacıyla, satış işlemini yapacak HONDA yetkili bayisi tarafından kendisine bildirilecek evrakı tamamlayacağını, Honda yetkili bayisi tarafından satış faturasının düzenlendiği tarihte tüm vergiler dahil belirlenen anahtar teslim araç bedeli üzerinden satın alacağını ve  HONDA yetkili bayisinin kabul ettiği ödeme yöntemiyle ödeyeceğini kabul eder. MÜŞTERİ ve  ilgili HONDA yetkili bayisi, araç satışı için işbu Sözleşme haricinde ayrı bir araç satış sözleşmesi akdedeceklerdir.
                        </p>
                        <p>
                            <span>3.6.</span>
                            MÜŞTERİ’ye rezervasyon tarihinden itibaren 3 iş günü içerisinde rezervasyon teyidi için ulaşılamaması, MÜŞTERİ’nin seçmiş olduğu HONDA yetkili bayisine gelmemesi, araç satışının sağlanması için kendisine bildirilen evrakı temin etmemesi/edememesi vb hallerinden birinin gerçekleşmesi halinde MÜŞTERİ, aracı satın alma isteğinden vazgeçmiş sayılır. Her halükarda rezervasyon tarihinden itibaren 3 iş günü sonra rezervasyon iptal edilmiş sayılır.
                        </p>
                        <p>
                           <span>3.7.</span>
                           MÜŞTERİ’nin elektronik ortamda ödemeyi teyit ettiği rezervasyon bedelinin herhangi bir nedenle ödenememesi, herhangi bir sebeple MÜŞTERİ’nin ödemeyi sağlayacağı kredi kartının rezervasyon bedeli tutarında bloke edilememesi ve/veya ödemenin banka kayıtlarında iptal edilmesi, MÜŞTERİ’ye ait kredi kartının yetkisiz kişilerce hukuka aykırı ve/veya haksız bir şekilde kullanıldığının tespit edilmesi hallerinde ilgili rezervasyon iptal edilmiş sayılacaktır.
                        </p>
                        <p>
                        Rezervasyonun işleme alınma zamanı, rezervasyonun yapıldığı an değil, kredi kartı hesabından rezervasyon bedelinin ödendiği an olup, bu ödemenin yapılamaması durumunda HONDA herhangi bir rezervasyon hizmeti sunmakla yükümlü olmayacaktır.
                        </p>
                        <p>
                            <span>3.8</span>
                            MÜŞTERİ’nin rezervasyon bedelini ödediği kredi kartının kendisine ait olup olmadığına ilişkin olarak HONDA nezdinde bir şüphe oluşması halinde HONDA, kredi kartı hamiline ilişkin kimlik ve iletişim bilgilerini, ödeme sırasında kullanılan kredi kartının bir önceki aya ilişkin ekstresini veya kart hamilinin bankasından düzenlenmiş ve kredi kartının kendisine ait olduğuna ilişkin yazıyı ibraz etmesini MÜŞTERİ’den talep edebilir. Bu bilgi ve belgeler temin edilene kadar, HONDA rezervasyon hizmeti vermeyi askıya alma veya mevcut rezervasyonu iptal etme hakkını saklı tutar.
                        </p>
                        <p>
                        Yapılan rezervasyon ödemelerinde, kartın hamili haricinde bir başkası tarafından hukuka aykırı şekilde kullanılması halinde Banka Kartları ve Kredi Kartları Kanunu ve Banka Kartları ve Kredi Kartları Hakkında Yönetmelik ve ilgili mevzuat hükümlerine göre işlem yapılır.
                        </p>
                        <p>
                            <span>3.9.</span>
                            MÜŞTERİ’nin aracı satın almaktan vazgeçtiği, vazgeçmiş sayılacağının kabul edildiği ya da herhangi bir sebeple rezervasyon işleminin iptal edildiği hallerde, HONDA tarafından yayımlanan kampanyalara ya da ilgili yetkili bayi tarafından kendisine tanınan fiyat, teslim, indirim gibi sair tüm hakları sona erer.  MÜŞTERİ, işbu durumda aracın kendisine satılamaması ya da işbu haklardan herhangi birinin kendisine sağlanamaması veya bir sonraki satın alma talebinde sağlanamayacak olması sebebiyle herhangi bir zararı doğduğunu ileri süremez, işbu zararın tazminini HONDA’dan ya da yetkili bayilerinden talep edemez.
                        </p>
                        <p>
                            <span>3.10.</span>
                            MÜŞTERİ, rezervasyon esnasında Web Sitesi üzerinden MÜŞTERİ’ye bildirilen araç bedelinin tavsiye satış bedeli niteliğinde olduğu, sabit olmadığı, doğrudan MÜŞTERİ’ye satış bedelinin HONDA tarafından belirlenmediği, her yetkili bayinin araç satış fiyatını bağımsız olarak belirlediği, aracın teslimine dek değişebileceği konusunda bilgilendirildiğini kabul, beyan ve taahhüt eder. Rezervasyon tarihi ile satış tarihi arasındaki araç bedelinde oluşabilecek fiyat farkından veya bu sebeple doğacak menfi veya müspet zararlardan HONDA veya yetkili bayileri sorumlu değildir.
                        </p>
                        <p>
                           <span>3.11.</span>
                           HONDA, rezervasyon esnasında bildirilen vergiler dahil tavsiye edilen perakende satış fiyatı bilgi amaçlı verilmiş olup, araca ilişkin satış faturasının düzenleneceği tarihe kadar olan dönem içinde ÖTV, KDV, Gümrük Vergisi, Taşıt Alım Vergisi, Motorlu Taşıtlar Vergisi, Plaka Harcı, Ruhsat Harcı, Noter Harcı ve burada sayılmayan tüm vergi ve harçlarda azalma ya da artışın olması halinde değiştirebilir ve anılan değişikliklerin anahtar teslim fiyatına yansıtılabilir. Aracın satış fiyatı, teslim sırasında satışı yapacak ilgili HONDA bayisi tarafından belirlenecek olup, HONDA veya yetkili bayileri doğacak fiyat farkından sorumlu tutulamayacaktır.
                        </p>
                        <p>
                            <span>3.12.</span>
                            MÜŞTERİ, Web Sitesi’ne rezervasyon hizmetinden yararlanmak için verdiği kişisel ve diğer tüm bilgilerin gerçeğe uygun olduğunu, işbu Sözleşme’yi ve aracın kesin alınacak olması halinde imzalanacak araç satış sözleşmesini akdetmek için fiil ehliyetine sahip olduğunu beyan eder. Aksi halde MÜŞTERİ’den rezervasyon bedeli tahsil edilmiş olsa dahi işbu Sözleşme’nin akdi kesin hükümsüz olacak ve doğacak zararlardan HONDA veya yetkili bayileri sorumlu olmayacaktır.
                        </p>
                        <p>
                            <span>3.13.</span>
                            MÜŞTERİ, Web Sitesi’nde özelliklerini seçtiği aracın temel nitelikleri, rezervasyon hizmet bedeli, rezervasyon hizmet bedeli ödeme şekli ve diğer tüm ön bilgileri okuyup, bilgi sahibi olduğunu, elektronik ortamda rezervasyon bedelinin ödenmesi için gerekli teyidi verdiğini, rezervasyon bedeli haricinde Web Sitesi üzerinden aldığı hizmetin herhangi bir ücrete tabi olmadığı konusunda bilgilendirildiğini kabul eder.
                        </p>
                        <p>
                            <span>3.14.</span>
                            MÜŞTERİ, Web Sitesi’ni kullanırken yasal mevzuat hükümlerine uygun davranmayı kabul eder. Aksi halde, doğacak tüm hukuki ve cezai yükümlülüklerden MÜŞTERİ tek başına sorumlu olacaktır.
                        </p>
                        <p>
                            <span>3.15.</span>
                            MÜŞTERİ, rezervasyon hizmeti ödeme işlemlerini kredi ve/veya banka kartıyla yapar. Kredi kartı borcunu ödemede temerrüde düşmesi durumunda ilgili banka ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı kendisinin sorumlu olacağını; bu durumda ilgili bankanın hukuki yollara başvurabileceğini, doğacak masraflar ve vekalet ücretinin MÜŞTERİ’den talep edilebileceğini ve ödeme kredi kartıyla veya başka şekilde yapılacak olsun olmasın her koşulda HONDA’nın uğrayacağı zarar ve ziyanı ödemekle yükümlü olduğunu kabul eder.
                        </p>
                        <h2>
                            4.
                            HONDA’NIN HAK VE YÜKÜMLÜLÜKLERİ
                        </h2>
                        <p>
                            <span>4.1.</span>
                            HONDA, MÜŞTERİ’nin Web Sitesi üzerinden özelliklerini seçtiği araca ilişkin özelliklerin gerçeği yansıttığını, rezervasyon hizmet bedelini ödeyen MÜŞTERİ’nin seçtiği aracı satışı yapacak ilgili yetkili bayisi aracılığıyla MÜŞTERİ’ye rezerve ettiğini, satış için ilgili yetkili bayisi ile mutabık kalan MÜŞTERİ’nin seçtiği aracı ilgili yetkili bayiye sevk işlemlerini yapacağını kabul ve beyan eder.
                        </p>
                        <p>
                            <span>4.2.</span>
                            MÜŞTERİ’nin işbu Sözleşme’de belirtilen yükümlülüklerini yerine getirmemesi, işbu Sözleşme’nin 3.6., 3.7. ve 3.8. maddelerinde sayılan hallerden birinin gerçekleşmesi, MÜŞTERİ’nin araç ticareti ile uğraştığının ve işbu Sözleşme’nin amacına aykırı olarak satış fiyatlarını suistimal etmek amacıyla araç satın almak istemesinin tespiti, MÜŞTERİ’nin kendisine tanınan hakları kötüye kullanması, fiil ehliyetine sahip olmaması veya herhangi bir sebeple satışın yapılmasının mümkün olmaması hallerinde, HONDA veya ilgili yetkili bayisi rezervasyonu iptal edebilir ve/veya satıştan imtina edebilir.
                        </p>
                        <p>
                            <span>4.3.</span>
                            HONDA, veya ilgili yetkili bayisi rezervasyonu yapılan araç için belirtmiş olduğu anahtar teslim fiyatın teslim anına dek sabit kalacağı konusunda MÜŞTERİ’ye garanti vermemektedir.
                        </p>
                        <p>
                           <span>4.4.</span>
                           HONDA veya ilgili yetkili bayisi, rezerve edilen aracın teslimi herhangi bir şekilde imkansız hale geldiği, araçta ayıp tespit edildiği takdirde derhal MÜŞTERİ’ye bildirimde bulunarak rezervasyonu iptal edecek ve rezervasyon ücretini iade edecektir. İşbu durumda MÜŞTERİ, herhangi bir sebeple zarara uğradığını iddia edemez, HONDA’den zararın tazminini talep edemez.
                        </p>
                        <p>
                            <span>4.5.</span>
                            HONDA, tek taraflı olarak, işbu Sözleşmede yer alan şartları Web Sitesi’nde değiştirme hak ve yetkisine sahiptir. İşbu şartlar değişikliğin yapıldığı tarihten itibaren geçerlilik kazanacaktır.
                        </p>
                        <h2>
                            5.
                            ÖDEME
                        </h2>
                        <p>
                            <span>5.1.</span>
                            Rezervasyon hizmet bedeli 50.000,00-TL’dir (ElliBinTürkLirası). MÜŞTERİ’nin, Web Sitesi üzerinden online ödeme araçları vasıtasıyla ödeme onayı vermesine istinaden MÜŞTERİ’nin online ödemede kullandığı kredi kartı, rezervasyon bedeli kadar bloke edilecektir. MÜŞTERİ, rezervasyon hizmet bedelini ödemediği takdirde işbu Sözleşme ile kendisine tanınan haklardan yararlanamayacaktır. İşbu bedelin ödenmesinden itibaren 3 iş günü sonunda HONDA, MÜŞTERİ’nin aracı satin alıp almamasıyla, rezervasyonun iptal edilmesiyle, satış işleminden vazgeçilmiş sayılmasıyla bağlı olmaksızın, herhangi bir masraf, komisyon, her ne ad altında olursa olsun herhangi bir bedel kesintisi yapmaksızın işbu bedeli MÜŞTERİ’ye aynen iade edecektir.
                        </p>
                        <p>
                            <span>5.2.</span>
                            HONDA işbu bedelde değişiklik yapma hakkını saklı tutar. HONDA kendi inisiyatifiyle işbu bedelde indirim yapabilir ya da işbu bedelden tamamen feragat de edebilir.
                        </p>
                        <p>
                            <span>5.3.</span>
                            İşbu Sözleşme ile Web Sitesi’nde MÜŞTERİ’ye bildirilen anahtar teslim tavsiye edilen perakende satış fiyatı aracın tüm vergiler ve devir masrafları dahil toplam fiyatıdır. İşbu fiyat, aracın satın alınacağı tarihe kadar olan süre içerisinde ticari nedenlerle, yasal ve idari düzenlemeler doğrultusunda artan vergi veya işlem giderleri nedeniyle değişebilir. MÜŞTERİ’ye rezervasyon aşamasında bildirilen araç fiyatı ile teslim aşamasında bildirilen araç fiyatı arasındaki farktan MÜŞTERİ sorumludur
                        </p>
                        <p>
                            <span>5.4.</span>
                            İşbu Sözleşme kapsamında MÜŞTERİ’nin ödeyeceği rezervasyon bedeli karşılığında fatura yerine sadece elektronik ortamda ödemenin yapıldığına ilişkin belge düzenlenip MÜŞTERİ’ye verilecektir.
                        </p>
                        <h2>
                            6.
                            SÖZLEŞMENİN FESHİ
                        </h2>
                        <p>
                            <span>6.1.</span>
                            MÜŞTERİ’nin, satış için aracın hazır edileceği yetkili bayiye gelmediği, aracı satın almaktan vazgeçtiği, vazgeçmiş sayılacağı hallerden birinin gerçekleştiği, MÜŞTERİ’nin Sözleşme’de yer alan yükümlülüklerden birini yerine getirmediği, aracın tesliminin ayıplı olması sebebiyle yapılamaması ya da herhangi bir sebeple imkansız olması, MÜŞTERİ’nin otomobil ticareti ile uğraştığının ve işbu Sözleşme’nin amacına aykırı olarak satış fiyatlarını suistimal edebileceğinin tespiti, MÜŞTERİ’nin kendisine tanınan hakları kötüye kullandığı, fiil ehliyetine sahip olmadığının tespiti hallerinde Sözleşme başka herhangi bir bildiriye gerek kalmadan feshedilmiş sayılır.
                        </p>
                        <p>
                            <span>6.2.</span>
                            Rezerve edilen aracın tesliminin yapılamayacak olması halinde HONDA veya ilgili yetkili bayisi, aracın satış faturasının düzenlenmesine kadar bu durumu derhal e-posta ve/veya SMS ile yazılı olarak MÜŞTERİ’ye bildirerek ve rezervasyon bedelini iade ederek herhangi bir tazminat yükümü altına girmeyecektir.
                        </p>
                        <p>
                            <span>6.3.</span>
                            MÜŞTERİ, Sözleşme’nin feshi dolayısıyla hiçbir koşulda HONDA’dan ve yetkili bayilerinden ödemiş olduğu rezervasyon bedelinin iadesi hariç herhangi bir hak, alacak, cezai şart, masraf ve her ne nam veya ad altında olursa olsun herhangi bir bedel talebinde bulunmayacağını kabul, beyan ve taahhüt eder.
                        </p>
                        <h2>
                            7.
                            MUHTELİF HÜKÜMLER
                        </h2>
                        <p>
                            <span>7.1.</span>
                            Tarafların makul kontrolü haricinde ve Tarafların gerekli özeni göstermeleri halinde dahi önleyemecekleri, doğal afet, isyan, savaş, grev, lokavt, ihracat ve ithalat yasakları, pandemi, kurdaki aşırı dalgalanma sebebiyle ithalatın durması, resmi makamlar tarafından verilen faaliyeti engelleyici kararlar ve hukuken mücbir sebep sayılan sair tüm durumlarda Taraflar işbu Sözleşme ile belirlenen edimlerinden herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle yükümlü değildir ve Taraflar birbirlerinden herhangi bir nam altında tazminat talep edemeyecektir. Mücbir sebep halinin, 10 günden fazla sürmesi halinde Taraflardan biri işbu Sözleşmeyi, tazminat, cezai şart vb. her ne nam ve ad altında olursa olsun herhangi bir bedel ödemeksizin feshedebileceklerdir.
                        </p>
                        <p>
                            <span>7.2.</span>
                            İşbu Sözleşme kapsamında HONDA markası ve logosu, web sitesi, ara yüz, ilan veri tabanı, içerik veri tabanı, tasarım, metin, imge, html kodu ve diğer kodlar da dâhil ve fakat bunlarla sınırlı olmamak kaydıyla tüm ögeleri, web sitesinin tasarımı, yazılımı ve bu çerçevede HONDA tarafından oluşturulan/oluşturulacak her türlü marka, logo, tasarım, ticari takdim şekli, slogan ve diğer tüm içeriğin her türlü fikri mülkiyet hakkı HONDA’nın mülkiyetindedir. Işbu mülkiyete tâbi fikri haklar, telif hakları, marka ve tasarım hakları ve/veya Web Sitesi’nde yer alan herhangi bir içerik her ne suretle olursa olsun elde edilemez, işlenemez, kullanılamaz, paylaşılamaz, sergilenemez, çoğaltılamaz, dağıtılamaz, kopyalanamaz ve/veya bu unsurları içerecek şekilde herhangi bir çalışma yapılmasına kesinlikle izin verilemez.
                        </p>
                        <p>
                            <span>7.3.</span>
                            MÜŞTERİ, işbu Sözleşme kapsamında elde etmekte olduğu hak ve yükümlülüklerini üçüncü gerçek veya tüzel kişilere kısmen veya tamamen doğrudan doğruya devir veya temlik edemez. İşbu yasak tek taraflı düzenlenmiş olup HONDA herhangi bir devir veya temlik yasağı ile bağlı değildir.
                        </p>
                        <p>
                            <span>7.4.</span>
                            HONDA, işbu Sözleşme ile işbu Sözleşme kapsamında sunacağı hizmet şartlarında, Web Sitesi üzerinden verilen hizmet ve servislerde, fiyat politikalarında her zaman önceden bildirim yapmaksızın değişiklik yapma hakkını saklı tutar.
                        </p>
                        <p>
                            <span>7.5.</span>
                            Tarafların tebligat adresleri sözleşmenin 1. Maddesinde yer alan ikamet veya e-posta adresleridir. Adres değişikliği olması halinde MÜŞTERİ, adres değişikliğini HONDA’ya derhal ve yazılı olarak bildirmediği takdirde, HONDA’nın sözleşmede yazılı adreslere gönderdiği tebligatlar bila tebliğ iade olunsa dahi, muhatabına yapılmış sayılacak ve geçerli bir tebligatın tüm hukuki sonuçlarını doğuracaktır.
                        </p>
                        <p>
                             <span>7.6.</span>
                             Işbu Sözleşme’nin uygulanmasından doğan her türlü ihtilaf MÜŞTERİ’nin tüketici ya da alıcı sıfatına ve uyuşmazlığın parasal değerine göre tüketici hakem heyetleri veya görevli mahkemelerde çözümlenecektir. Görevli mahkemelerde çözümlenecek uyuşmazlıklar yönünden taraflar, İstanbul Anadolu Mahkemeleri’ni yetkili kılmışlardır.
                        </p>
                        <p>
                            <span>7.7.</span>
                            Taraflardan birinin işbu Sözleşme’den doğan yükümlülüğünü yerine getirmemesine dayanarak, diğer tarafın işbu Sözleşme’den doğan herhangi bir hakkını kullanmaması, kullanmakta gecikmesi, kullanmaktan açıkça veya zımnen imtina etmesi, işbu haktan feragat ettiği, işbu hakkın kullanımından vazgeçtiği, ileri vadede kullanmayacağı anlamına gelmemektedir.
                        </p>
                        <p>
                            İşbu Sözleşme toplam 7(yedi) maddeden ibaret olup, {moment().format("DD/MM/YYYY")} tarihinde elektronik ortamda düzenlenmiş ve taraflarca kabul edilmiştir.
                        </p>
                    </div>




                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => ({
    personalData: state.commonReducer.personalInfoFormData || {}
})

export default connect(mapStateToProps)(ReservationLbComponent)
