import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {ASSETS} from "../../constants/paths";
import LazyLoad from "react-lazyload";
import {getElementWidth} from "../../helper";

class SmallSliderComponent extends Component {
    imgItem = React.createRef();
    miniSliderBlock = React.createRef();
    container = React.createRef();
    galleryLength= Math.ceil(JSON.parse(this.props.selectedColor.detail.gallery_images).length/10)
   
    state = {
        itemWidth: 0,
        blockArea: 0,
        smallAreaIndex: 0,
        translateValue: 0,
        dotIndex: 0
    }
    calculateItemWidth = () => {
        this.setState({
            itemWidth: getElementWidth(this.imgItem.current),
            blockArea: this.container.current.offsetWidth,
        })
    }
    setTranslateValue = () => {
        this.calculateItemWidth()
      
        let {activeIndex, children,isMobile} = this.props;
        
        if(isMobile){
            if (children.length - 1 > activeIndex) {
                this.setState({
                    translateValue: 0
                })
            }
            if (activeIndex > 2) {
                this.setState({
                    translateValue: this.state.itemWidth * (activeIndex - 2)
                })
            }
        }else{
            this.setState({
                translateValue: this.miniSliderBlock.current.offsetWidth * (Math.floor(activeIndex / 10)),
                dotIndex: Math.floor(activeIndex / 10)
            })
        }

    }
   
    changeSmallAreaIndex = (activeIndexSm)=>{
        this.setState({
            translateValue: this.miniSliderBlock.current.offsetWidth * activeIndexSm,
            dotIndex: activeIndexSm
        })
       
        
     }
    
   
    componentDidMount() {
        /*todo: başka yol olabilir timeout haric !! {emre}*/
        
        setTimeout(() => {
            this.calculateItemWidth()
        }, 300)
        if (typeof window !== "undefined") {
            window.addEventListener("resize",this.setTranslateValue)
        }
        
    }


    componentDidUpdate(prevProps) {
        if (prevProps.activeIndex !== this.props.activeIndex) {
            this.setTranslateValue()
        }  
        
    }

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.setTranslateValue)
        }
    }
   
    render() {
        let {selectedColor, activeIndex,isMobile,slideFunc} = this.props
        let imgCount = JSON.parse(selectedColor.detail.gallery_images).length
        let miniSliderBlockCount = Math.ceil(imgCount / 10)
       
        
        return (
            <Fragment>
                <div ref={this.container} className={`mini-img-block ${!isMobile ? "desktop" : ""}`} style={{
                    transform: !isMobile ? `translate3d(-${this.state.translateValue}px,0,0)` : null
                    }}>
                    {
                        isMobile &&
                        <div className="mini-slider-block" style={{transform: `translate3d(-${this.state.translateValue}px,0,0)`}} >
                        {
                            (selectedColor&&selectedColor.detail&&selectedColor.detail.gallery_images) && JSON.parse(selectedColor.detail.gallery_images).map((img, index) =>

                                    <div ref={index === 1 ? this.imgItem : this.colorImgItem} 
                                        className={`img-item ${activeIndex === index ? "active" : ""}`} key={index}
                                        onClick={() => this.props.changeIndex(index)}>
                                           
                                        <LazyLoad>
                                            <picture>
                                                <source
                                                    srcSet={`${ASSETS}${img.substring(0, img.lastIndexOf("."))}.webp 1x, ${ASSETS}${img.substring(0, img.lastIndexOf("."))}_2x.webp 2x`}/>
                                                <source
                                                    srcSet={`${ASSETS}${img} 1x, ${ASSETS}${img.substring(0, img.lastIndexOf("."))}_2x${img.substring(img.lastIndexOf("."))} 2x`}/>
                                                <img src={`${ASSETS}${img}`}  alt={selectedColor.name}/>
                                            </picture>
                                        </LazyLoad>
                                    </div>
                                   
                            )
                        }
                    </div>
                    }
                    
                  
                    {
                        !isMobile &&
                            <Fragment>
                                    {
                                        JSON.parse(selectedColor.detail.gallery_images).length &&
                                        Array.from({length: miniSliderBlockCount}).map((data,inx)=>
                                            <div key={inx} ref={this.miniSliderBlock} className="mini-slider-block" >
                                                {
                                                    (selectedColor&&selectedColor.detail&&selectedColor.detail.gallery_images) && JSON.parse(selectedColor.detail.gallery_images).slice(inx*10,(inx+1)*10).map((img, index) =>
                                                        <div
                                                            className={`img-item ${activeIndex === index + (10 * inx)  ? "active" : ""}`} key={index}
                                                            onClick={() => this.props.changeIndex(index + (10 * inx))}>
                                                            <LazyLoad>
                                                                <picture>
                                                                    <source
                                                                        srcSet={`${ASSETS}${img.substring(0, img.lastIndexOf("."))}.webp 1x, ${ASSETS}${img.substring(0, img.lastIndexOf("."))}_2x.webp 2x`}/>
                                                                    <source
                                                                        srcSet={`${ASSETS}${img} 1x, ${ASSETS}${img.substring(0, img.lastIndexOf("."))}_2x${img.substring(img.lastIndexOf("."))} 2x`}/>
                                                                    <img src={`${ASSETS}${img}`}  alt={selectedColor.name}/>
                                                                </picture>
                                                            </LazyLoad>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    
                                    } 
                            </Fragment>
                    }
                   
                   
                </div>
                  {
               !isMobile &&
               <div className="slider-info-container">
                  <div className="slide-num-item">
                        <p>{activeIndex + 1}/{imgCount} Fotoğraf</p>
                  </div>
                  <div className="slider-nav-block">
                        <div className={`arr-item desktop`} onClick={() => slideFunc(-1)}/> 
                     <div className="dot-block">
                        {
                           Array.from({length:miniSliderBlockCount}).map((item,index)=>
                              <span key={index} className={`${this.state.dotIndex === index ? "active" : ""}`} 
                                onClick={()=>{
                                    this.changeSmallAreaIndex(index);
                            }}  />
                           )
                        }
                     </div>
                     <div className={`arr-item right-arr desktop`} onClick={() => slideFunc(1)}/>
                  </div>
               </div>
             }
            </Fragment>
                
                
          
          
        );
    }
}

const mapStateToProps = (state) => ({
    selectedColor: state.userSelectionReducer.selectedColor
})
export default connect(mapStateToProps)(SmallSliderComponent);
