import {RECEIVE_MODELS} from "../constants/actionTypes";

const initialValues = {}

const modelsReducer=(state=initialValues,action)=>{
    switch (action.type){
        case RECEIVE_MODELS:
            return{
                ...state,
                models:action.payload
            }
        default:
            return state;
    }
}
export default modelsReducer;