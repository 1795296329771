
import {GET_PERSONEL_DATA,RECEVIVE_DEALERS_BY_MODEL,RECEIVE_USER_SELECTION,RECEIVE_TRANSACTION_INFO} from "../constants/actionTypes";

const initialStates = {}
const personelReducer = (state = initialStates, action) => {
   switch (action.type) {
      case GET_PERSONEL_DATA:
         return {
            ...state,
            version_detail: action.payload
         }
      case RECEVIVE_DEALERS_BY_MODEL:
         
         return {
            ...state,
            ...action.payload
         }
      case RECEIVE_USER_SELECTION:
         return {
            ...state,
            selectedModel:action.payload.selectedModel
         }
      case RECEIVE_TRANSACTION_INFO:
         return {
            ...state,
            selectedModel:action.payload.vehicleDetail.selectedModel
         }
      default :
         return state
   }
}
export default personelReducer;
