import React, {Component} from "react";
import {ASSETS} from '../../constants/paths';
import LazyLoad from "react-lazyload";
import {connect} from "react-redux";
import LoaderComponent from "../common/loaderComponent";
import {PAYZEE_AMOUNT} from "../../constants/const";


class SummaryVehicleComponent extends Component {
   render() {
      let {selectedModel, toggleLightBox, selectedColor, version_detail} = this.props;


      return (
          selectedModel && selectedColor ?
              <div className="car-select-block">
                 {
                    (selectedColor.detail && selectedColor.detail.result_img) &&
                    <div className="img-item">
                       <LazyLoad>
                          <picture>
                             <source
                                 srcSet={`${ASSETS}${selectedColor.detail.result_img.substring(0, selectedColor.detail.result_img.lastIndexOf("."))}.webp 1x, ${ASSETS}${selectedColor.detail.result_img.substring(0, selectedColor.detail.result_img.lastIndexOf("."))}_2x.webp 2x`}/>
                             <source
                                 srcSet={`${ASSETS}${selectedColor.detail.result_img} 1x, ${ASSETS}${selectedColor.detail.result_img.substring(0, selectedColor.detail.result_img.lastIndexOf("."))}_2x${selectedColor.detail.result_img.substring(selectedColor.detail.result_img.lastIndexOf("."))} 2x`}/>
                             <img src={`${ASSETS}${selectedColor.detail.result_img}`} alt={`${selectedModel.model}-${selectedColor.detail.color_name}`}/>
                          </picture>
                       </LazyLoad>
                    </div>
                 }

                 <div className="name-item">
                    <h2>{selectedModel.model}</h2>
                 </div>
                 <ul className="features-block">
                    <li>{selectedModel.engine}</li>
                    <li>{selectedModel.version}</li>
                    <li>{selectedModel.gearbox_type}</li>
                    <li>{selectedColor.detail && selectedColor.detail.color_name}</li>
                 </ul>
                 <div className="price-detail-block">
                    <div className="price-item">
                       <p>{selectedModel.model}</p>
                       <span>{version_detail && version_detail.year && version_detail.year.price ? version_detail.year.price.toLocaleString("tr-TR") + " TL" : null}</span>
                       <p>Tavsiye Edilen Fiyat</p>
                    </div>
                    {/*<div className="price-item">
                       <p>Teslimat Tutarı</p>
                       <span>{PAYZEE_AMOUNT.substring(0, 2) + "." + PAYZEE_AMOUNT.substring(2, 5)} TL</span>
                    </div>*/}
                 </div>
                 <div className="stroke-btn" onClick={() => {
                    toggleLightBox()
                 }}>DEĞİŞTİR
                 </div>
              </div>
              :
              <LoaderComponent/>
      )
   }
};


const mapStateToProps = (state, ownProps) => ({
   selectedModel: state.personelReducer.selectedModel ? state.personelReducer.selectedModel : null,
   selectedColor: state.userSelectionReducer.selectedColor ? state.userSelectionReducer.selectedColor : null,
   version_detail: state.userSelectionReducer.version_detail || null,
})

export default connect(mapStateToProps)(SummaryVehicleComponent);
