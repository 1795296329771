import {EMPTY_TRANSACTION, GET_PERSONEL_DATA,SAVE_TRANSACTION,RECEIVE_TRANSACTION_INFO} from "../constants/actionTypes";

export const receivePersonel = (payload) => ({
    type: GET_PERSONEL_DATA,
    payload
});

export const saveTransactionIDAction = (payload) => ({
    type: SAVE_TRANSACTION,
    payload
}) 

export const emptyTransactionIDAction = () => ({
    type: EMPTY_TRANSACTION
})

export const receiveTransactionInfo = (payload) => ({
    type: RECEIVE_TRANSACTION_INFO,
    payload
})