import {
   DETAIL_INFO_DATA,
   GET_MODEL_COLOR, GET_USER_VERSION_DETAIL,
   GET_VEHICLE_DETAIL,
   PERSONAL_INFO_DATA,
   GET_PERSONEL_DATA,
   GET_BREADCRUMB_LINK, GET_SELECTED_COLOR,
   RECEIVE_USER_SELECTION, FORM_DIRTY_VALUE, HTML_HEIGHT, IS_BC_OPEN
} from "../constants/actionTypes";
import {
   getTransactionInfo,
   getVehicleDetail,
   saveReservation,
} from "../services/webService";

export const getPersonalInfoFormData = (payload) => ({
   type: PERSONAL_INFO_DATA,
   payload
})
export const getDetailInfoFormData = (payload) => ({
   type: DETAIL_INFO_DATA,
   payload
})
export const getUserVersionDetail = (payload) => ({
   type: GET_USER_VERSION_DETAIL,
   payload
})
export const getModelColor = (payload) => ({
   type: GET_MODEL_COLOR,
   payload
})

export const getDetailData = (payload)=> dispatch => {
   return new Promise((reject, resolve) => {
      getVehicleDetail(payload).then(res => {
         console.log("------------->", res)
      })
   })
}
export const getVehicleDetailData = (payload) => ({
   type: GET_VEHICLE_DETAIL,
   payload
});

export const getPersonelData = (payload) => ({
   type: GET_PERSONEL_DATA,
   payload
})

export const saveReservationAction =  (params,cb) => dispatch => {
   saveReservation(params).then((res)=>{
      if(res.data.succes){
         cb(res.data)
      }else{
         cb(false);
      }
   }).catch((err)=>{
      cb(false)
   })
}
export const getBreadCrumbLink=(payload)=>({
   type:GET_BREADCRUMB_LINK,
   payload
})
export const getSelectedColor=(payload)=>({
   type:GET_SELECTED_COLOR,
   payload
})

export const receiveUserSelection=(payload)=>({
   type: RECEIVE_USER_SELECTION,
   payload
})

export const getTransactionInfoAction = (transactionId) => dispatch => {
   getTransactionInfo(transactionId).then((res)=>{
      if(res.data.success){
         dispatch(receiveUserSelection(res.data.success))
      }
   }).catch((err)=>{
      console.log("err",err)
   })
}
export const isFormDirtyAction=payload=>({
   type:FORM_DIRTY_VALUE,
   payload
})
export const getHtmlHeight=payload=>({
   type:HTML_HEIGHT,
   payload
})
export const isBcOpen=payload=>({
   type:IS_BC_OPEN,
   payload
})

