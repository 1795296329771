import React, {Component} from 'react';
import {Link, NavLink} from "react-router-dom";
import {ASSETS} from '../../constants/paths';
import {SELECT_CAR_PAGE, HOME_PAGE, WHY_ONLINE_PAGE, LEGAL_WARNING, KVKK_PAGE} from "../../constants/routePaths";

class HeaderComponent extends Component {
    pageService;
    state={
        isSticky:false,
        opened:false
    }

    componentDidMount() {
        if (typeof window !== "undefined"){
            window.addEventListener("scroll", this.addStickyToHeader)
        }
    }
    addStickyToHeader = () => {
        if (typeof window !== "undefined" && window.scrollY > 0) {
            this.setState({
                isSticky: true
            })
        } else {
            this.setState({
                isSticky: false
            })
        }
    }
    toggleMobileMenu = () => {
        this.setState({
            opened: !this.state.opened
        },()=>{
            if (this.state.opened && window.innerWidth < 640) {
                document.body.classList.add("no-scroll");
            } else {
                document.body.classList.remove('no-scroll');
            }
        })
    }
 componentWillUnmount() {
     if (typeof window !== "undefined"){
         window.removeEventListener("scroll", this.addStickyToHeader)
     }
 }

    render() {
        return (
            <header className={`header-root ${this.state.isSticky ? "sticky-header" : ""}  ${this.state.opened ? "opened" : ""}`}>
                <div className="header-container">
                    <div className="header-block">
                        <NavLink exact to={SELECT_CAR_PAGE}>
                            <div className="logo-item">

                                <picture>
                                    <source
                                        media="(min-width: 431px)"
                                        srcSet={`${ASSETS + "/img/logo.webp"} 1x, ${ASSETS + "/img/logo_2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        media="(min-width: 431px)"
                                        srcSet={`${ASSETS + "/img/logo.png"} 1x, ${ASSETS + "/img/logo_2x.png"} 2x `}
                                        type="image/jpeg"/>
                                    <source
                                        media="(max-width: 430px)"
                                        srcSet={`${ASSETS + "/img/logo-mobile.webp"} 1x, ${ASSETS + "/img/logo-mobile_2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        media="(max-width: 430px)"
                                        srcSet={`${ASSETS + "/img/logo-mobile.png"} 1x, ${ASSETS + "/img/logo-mobile_2x.png"} 2x `}
                                        type="image/jpeg"/>
                                    <img className="lazy" src={ASSETS + "/img/logo.png"}
                                         alt="logo"/>
                                </picture>
                            </div>
                            <div className="logo-item">

                                <picture>
                                    <source
                                        media="(min-width: 431px)"
                                        srcSet={`${ASSETS + "/img/online-logo.webp"} 1x, ${ASSETS + "/img/online-logo_2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        media="(min-width: 431px)"
                                        srcSet={`${ASSETS + "/img/online-logo.png"} 1x, ${ASSETS + "/img/online-logo_2x.png"} 2x `}
                                        type="image/jpeg"/>
                                    <source
                                        media="(max-width: 430px)"
                                        srcSet={`${ASSETS + "/img/online-logo-mobile.webp"} 1x, ${ASSETS + "/img/online-logo-mobile_2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        media="(max-width: 430px)"
                                        srcSet={`${ASSETS + "/img/online-logo-mobile.png"} 1x, ${ASSETS + "/img/online-logo-mobile_2x.png"} 2x `}
                                        type="image/jpeg"/>
                                    <img className="lazy" src={ASSETS + "/img/online-logo.png"}
                                         alt="logo"/>
                                </picture>
                            </div>
                        </NavLink>
                        <ul className={`menu-block ${this.state.opened ? "opened" : ""}`} >
                            <li><Link onClick={() => {this.toggleMobileMenu()}} className={`first-menu ${ (this.props.location.pathname !== HOME_PAGE && this.props.location.pathname !== WHY_ONLINE_PAGE && this.props.location.pathname !== LEGAL_WARNING && this.props.location.pathname !== KVKK_PAGE) ? "active":""} `} to={SELECT_CAR_PAGE}>Aracınızı seçin</Link></li>

                            <li><NavLink onClick={() => {this.toggleMobileMenu()}} className="first-menu" to={HOME_PAGE}>Satın alma yöntemi</NavLink></li>

                            <li><NavLink onClick={() => {this.toggleMobileMenu()}} className="first-menu" to={WHY_ONLINE_PAGE}>Neden Honda Online</NavLink></li>

                            <li><a onClick={() => {this.toggleMobileMenu()}} className="first-menu" href="https://honda.com.tr/otomobil/sizi-arayalim"
                                   target="_blank" rel="noreferrer">İletişim</a></li>
                        </ul>
                        <div className="mobile-menu-block">
                            <div className={`menu-btn ${this.state.opened?"active":""}`} onClick={() => {this.toggleMobileMenu()}}>
                                <span/>
                            </div>

                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default HeaderComponent;
