import React, { Component } from 'react'
import { connect } from 'react-redux'

class SummaryPersonInfoComponent extends Component {

   componentDidMount() {
      if(typeof window!=="undefined"){
         this.setDataLayer()
         this.orderDataLayer()
      }
   }

   setDataLayer=()=>{
      if(!window.dataLayer) window.dataLayer = window.dataLayer || []
      if(this.props.selectedModel){
         const selectedCityValue=this.props.personalData.dealer_city.value;
         const index = this.props.dealers.findIndex(dealer => dealer.city_code === selectedCityValue);
         window.dataLayer.push({
            event: "add_shipping_info",
            ecommerce: {
               currency: "TRY",
               value: "50000", //rezervasyon bedeli
               coupon: "",
               citySelection: this.props.personalData && this.props.personalData.dealer_city && this.props.personalData.dealer_city.label,
               'districtSelection':this.props.personalData && this.props.personalData.dealer_city && this.props.dealers && this.props.dealers[index] && this.props.dealers[index].district_name,
               dealerSelection:this.props.personalData && this.props.personalData.dealer && this.props.personalData.dealer.label, // Seçilmiyorsa boş gelebilir.
               deliveryMethod:"Bayiden Teslim",
               items: [
                  {
                     item_id:  this.props.selectedModel.dms_model_code,
                     item_name: this.props.selectedModel.model,
                     item_brand: "Honda",
                     item_category: "Otomobil",
                     item_variant: this.props.selectedModel.engine,
                     price: this.props.version_detail && this.props.version_detail.year && this.props.version_detail.year.price ? this.props.version_detail.year.price.toLocaleString("tr-TR") + " TL" : null,

                     color:this.props.selectedModel.colors && this.props.selectedModel.colors[0] && this.props.selectedModel.colors[0].name,
                     model:this.props.selectedModel.model,
                     gear:"Otomatik",
                     motor:this.props.selectedModel.engine,
                     quantity: 1
                  }
               ]
            }
         });
      }

   }
   orderDataLayer=()=>{
      if(typeof window!=="undefined")
      if(!window.dataLayer) window.dataLayer = window.dataLayer || []
      if(this.props.selectedModel){
         const selectedCityValue=this.props.personalData.dealer_city.value;
         const index = this.props.dealers.findIndex(dealer => dealer.city_code === selectedCityValue);
         window.dataLayer.push({
            'event': 'order_summary',
            model:this.props.selectedModel.model,
            'vehicle': 'Otomobil',
            price: this.props.version_detail && this.props.version_detail.year && this.props.version_detail.year.price ? this.props.version_detail.year.price.toLocaleString("tr-TR") + " TL" : null,
            'gear': 'Otomatik',
            color:this.props.selectedModel.colors && this.props.selectedModel.colors[0] && this.props.selectedModel.colors[0].name,
            motor:this.props.selectedModel.engine,
            citySelection: this.props.personalData && this.props.personalData.dealer_city && this.props.personalData.dealer_city.label,
            'districtSelection':this.props.personalData && this.props.personalData.dealer_city && this.props.dealers && this.props.dealers[index] && this.props.dealers[index].district_name,
            dealerSelection:this.props.personalData && this.props.personalData.dealer && this.props.personalData.dealer.label, // Seçilmiyorsa boş gelebilir.
            'deliveryMethod':"Bayiden Teslim"

         });
      }

   }


   render() {
      let { personalData,version_detail } = this.props;
      return (
         <div>
            {
               Object.keys(personalData).length > 0 &&
               <div className="order-summery-block">
                  <div className="title-block">
                     <h1>Rezervasyon özetiniz</h1>
                     <p>Honda otomobilinizin rezervasyon bilgilerini son kez gözden geçirin.</p>
                  </div>
                  <div className="personal-info-container">
                     <div className="head-item">
                        <h3>
                           Kişisel Bilgiler
                         </h3>
                     </div>
                     <div className="info-block">
                        <div className="info-item">
                           <p>Ad Soyad:</p>
                           <span>{personalData.name}</span>
                        </div>

                        <div className="info-item">
                           <p>Telefon Numarası:</p>
                           <span>{personalData.phone}</span>
                        </div>
                        <div className="info-item">
                           <p>E-Posta:</p>
                           <span>{personalData.email}</span>
                        </div>
                        <div className="info-item">
                           <p>TC Kimlik Numarası:</p>
                           <span>{personalData.tckn}</span>
                        </div>
                     </div>
                     <div className="head-item">
                        <h3>
                           Teslimat Bilgileri
                         </h3>
                     </div>
                     <div className="info-block delivery-info">
                        <div className="info-item">
                           <p>Teslimat Yöntemi:</p>
                           <span>{!personalData.address ? "Bayiden Teslim" : "Adrese Teslim"}</span>
                        </div>
                        {
                           (personalData.dealer_city || personalData.city) &&
                           <div className="info-item">
                              <p>Şehir:</p>
                              <span>{!personalData.address ? personalData.dealer_city.label : personalData.city.label}</span>
                           </div>
                        }
                        {
                           (personalData.dealer || personalData.district) &&
                           <div className="info-item">
                              <p>{!personalData.address ? "Bayi:" : "İlçe:"}</p>
                              <span>{!personalData.address ? personalData.dealer.label : personalData.district.label}</span>
                           </div>
                        }

                        {
                           personalData.address &&
                           <div className="info-item address">
                              <p>Açık adres:</p>
                              <span>{personalData.address}</span>
                           </div>
                        }


                     </div>


                  </div>
               </div>
            }
         </div>


      )
   }
};


const mapStateToProps = (state, ownProps) => ({
   personalData: state.commonReducer.personalInfoFormData || {},
   selectedModel: state.personelReducer.selectedModel || null,
   version_detail: state.userSelectionReducer.version_detail || null,
   dealers: state.personelReducer.dealers

})

export default connect(mapStateToProps)(SummaryPersonInfoComponent)
