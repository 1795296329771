import React, {Component} from 'react';
import {matchPath, Redirect, Route, Switch, withRouter} from 'react-router-dom'
import {
    CONTRACT,
    HOME_PAGE,
    KVKK_PAGE,
    LEGAL_WARNING,
    PAYMENT_RESULT,
    PAYMENT_RESULT_WITHOUT_PARAM,
    PAYMENT_STAGE,
    PAYMENT_STAGE_WITHOUT_PARAM,
    PERSONAL_INFO,
    SELECT_CAR_PAGE,
    SELECT_DESIGN,
    SELECT_VERSION,
    SUMMARY_INFO,
    WHY_ONLINE_PAGE
} from "../constants/routePaths";
import HomePage from "../pages/homePage";
import * as PageTypes from "../constants/pageTypes.json";
import HeaderComponent from "./layout/headerComponent";
import BreadCrumpComponent from "./breadCrumpComponent";
import SelectModelPage from "../pages/selectModelPage";
import SelectVersionPage from "../pages/selectVersionPage";
import SelectDesignPage from "../pages/selectDesignPage";
import PersonalInfoComponent from "../pages/personalInfoPage";
import FooterComponent from "./layout/footerComponent";
import OrderSummeryPage from "../pages/orderSummeryPage";
import PaymentPage from "../pages/paymentPage";
import PaymentResultPage from "../pages/paymentResultPage";
import WhyOnlinePage from "../pages/whyOnlinePage";
import LegalWarning from "../pages/legalWarning"
import {connect} from "react-redux";
import KvkkComponent from "../pages/kvkkPage";
import ContractPage from '../pages/contractPage';
import NotFoundPage from "../pages/notFoundPage";
import * as ROUTES from "../constants/routePaths";
import {BASE_URL_WITHOUT_SLASH} from "../constants/paths";
import {scrollToTop} from "../helper"



class RoutesComponent extends Component {
    cityName;
    districtName;
    landingPage = "";
    state={
        isGtmSet:false
    }
    constructor(props) {
        super(props);

        this.landingPage = props.location.pathname;
      //  this.setDataLayer(this.props.location.pathname, null, null, this.getPageType(this.props.location.pathname));

    }

    componentDidMount() {
        if(typeof window!=="undefined"){

        }
        this.landingPage = this.props.location.pathname;
        this.setDataLayer(this.props.location.pathname, null, null, this.getPageType(this.props.location.pathname));

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (typeof window !== "undefined" && prevProps.location.pathname !== this.props.location.pathname) {
            this.setDataLayer(this.props.location.pathname, this.cityName, this.districtName,this.getPageType(this.props.location.pathname));
            scrollToTop()
        }
    }



    setDataLayer = (pathname, city, district, pageType) => {
        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || [];

            if (!!window.google_tag_manager) {
                setTimeout(()=>{
                    window.dataLayer.push({
                        'event': 'virtual_pv',
                        'page_path': pathname,
                        'page_url': BASE_URL_WITHOUT_SLASH + pathname,
                        "page_type" : "Otomobil Online Rezervasyon",
                        'document_referrer': document.referrer,
                        'page_title':document.title,
                        'landing_page': this.landingPage,
                        'site_server': null,
                        'user_email': null,
                        'user_location_city': null,
                        'user_location_district': null
                    })
                },500)
        
            } else {
                window.addEventListener('gtm_loaded', ()=> {
                   setTimeout(()=>{
                    window.dataLayer.push({
                        'event': 'virtual_pv',
                        'page_path': pathname,
                        'page_url': BASE_URL_WITHOUT_SLASH + pathname,
                        "page_type" : "Otomobil Online Rezervasyon",
                        'document_referrer': document.referrer,
                        'page_title':document.title,
                        'landing_page': this.landingPage,
                        'site_server': null,
                        'user_email': null,
                        'user_location_city': null,
                        'user_location_district': null
                    })
                   },500)
        
                });
            }
        }
    };





    getPageType = (url) => {
        let routeTitleForPageType = "";
        let urlArr = url.indexOf("?") > -1 ? url.substring(0, url.indexOf("?")).split("/") : url.split("/");

        let urlParams = {};
        if (urlArr.length > 1) {
            let splitUrl = url.indexOf("?") > -1 ? url.substring(0, url.indexOf("?")).split("/") : url.split("/");
            splitUrl = splitUrl.slice(1, splitUrl.length);

            Object.keys(ROUTES).map((routeTitle, routeInx) => {
                let splitRoute = ROUTES[routeTitle].split("/");
                splitRoute = splitRoute.filter(x => x !== "");

                let finalRoute = "";

                if (splitUrl) {
                    if (splitRoute[0] === splitUrl[0] || splitUrl.length === 1) {
                        splitRoute.forEach((route, inx) => {
                            finalRoute = finalRoute + (splitUrl[inx] ? "/" + splitUrl[inx] : "");
                        });
                        let newUrl = url.indexOf("?") > -1 ? url.substring(0, url.indexOf("?")) : url;
                        if (newUrl === finalRoute) {

                            urlParams = matchPath(newUrl, {
                                path: ROUTES[routeTitle],
                                exact: false,
                                strict: false
                            }) ? matchPath(newUrl, {
                                path: ROUTES[routeTitle],
                                exact: false,
                                strict: false
                            }) : urlParams;
                            if (ROUTES[routeTitle] === newUrl) {
                                routeTitleForPageType = routeTitle;
                            } else {
                                if (urlParams.params && Object.keys(urlParams.params).length > 0) {
                                    if (urlParams.path === ROUTES[routeTitle] && urlParams.url === finalRoute) {
                                        routeTitleForPageType = routeTitle;
                                    }
                                }
                            }

                        }
                    }
                }

            });
            let pageTitle = PageTypes[routeTitleForPageType] || PageTypes.default[routeTitleForPageType]
            return (pageTitle);
        }
    };



    render() {
        let {breadCrumbLinks} = this.props;
        return (
            <div>
                <Switch>
                    <Route exact path={CONTRACT} component={null}/>
                    <Route component={HeaderComponent}/>
                </Switch>
                {
                    this.props.location.pathname !== HOME_PAGE && this.props.location.pathname !== WHY_ONLINE_PAGE && this.props.location.pathname !== LEGAL_WARNING && this.props.location.pathname !== KVKK_PAGE && `/${this.props.location.pathname.split("/")[1]}` !== PAYMENT_RESULT_WITHOUT_PARAM && breadCrumbLinks&&

                    <Switch>
                        <Route exact path={CONTRACT} component={null}/>
                        <Route  component={BreadCrumpComponent}/>
                    </Switch>
                }
                <Switch>
                    <Route exact path={HOME_PAGE} component={HomePage}/>
                    <Route exact path={LEGAL_WARNING}  component={LegalWarning}/>
                    <Route exact path={SELECT_CAR_PAGE}  component={SelectModelPage}/>
                    <Route exact path={SELECT_VERSION}  component={SelectVersionPage}/>
                    <Route exact path={SELECT_DESIGN} component={SelectDesignPage}/>
                    <Route exact path={PERSONAL_INFO} component={PersonalInfoComponent}/>
                    <Route exact path={SUMMARY_INFO} component={OrderSummeryPage}/>
                    <Route exact path={PAYMENT_STAGE}  component={PaymentPage}/>
                    <Route exact path={PAYMENT_RESULT}  component={PaymentResultPage}/>
                    <Route exact path={WHY_ONLINE_PAGE}  component={WhyOnlinePage}/>
                    <Route exact path={LEGAL_WARNING}  component={LegalWarning}/>
                    <Route exact path={KVKK_PAGE} component={KvkkComponent}/>
                    <Route exact path={CONTRACT}  component={ContractPage}/>
                    <Route component={NotFoundPage}/>
                </Switch>
                <Switch>
                    <Route exact path={CONTRACT} component={null}/>
                    <Route component={FooterComponent}/>
                </Switch>
                {
                    ((this.props.location.pathname === SUMMARY_INFO || this.props.location.pathname === PAYMENT_STAGE_WITHOUT_PARAM || this.props.location.pathname === PAYMENT_RESULT_WITHOUT_PARAM ) &&  !breadCrumbLinks) &&
                    <Redirect to={SELECT_CAR_PAGE}/>
                }
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    models: state.modelsReducer.models,
    breadCrumbLinks: state.commonReducer.breadCrumbLinks
})
export default withRouter(connect(mapStateToProps)(RoutesComponent));
