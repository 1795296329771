import React, {Component} from 'react';

class PersonalDataModal extends Component {
    componentDidMount(){
        if(typeof window !== undefined){
            document.body.classList.add('no-scroll')
        }
    }
    componentWillUnmount(){
        if(typeof window !== undefined){
            document.body.classList.remove('no-scroll')
        }
    }
    render() {
        return (
            <div>
                <div className="personel-data-lb-root  ">
                    <div className="lb-electronic-wrapper">
                        <div className="lb-block lb-table-block electropersonal">
                            <div className=" table-feature-root lb-electronic-table">
                                <div onClick={()=>this.props.personaDataToggle()} className="electronic-close-btn">
                                    <div className="close"></div>
                                </div>
                                <div className="lb-electronic-text-block">
                                    <div className="lb-electronic-text">
                                   <p>
                                    Honda Türkiye A.Ş. web sitesinde bulunan <span>Honda Online</span> alanından talep ve şikayetlerinizi karşılayabilmek ve sonuç hakkında bilgilendirebilmek amacıyla paylaşacağınız kişisel verilerinizi, haklarınıza zarar vermemek kaydı ile 6698 Sayılı Kişisel Verilerin Korunması Kanunu uyarınca işleyeceğiz.
                                   </p>
                                    </div>
                                    <div className="lb-electronic-text">
                                      <p>
                                          Yalnızca <span>Honda Online</span> alanından paylaştığınız kişisel veriler, iş ortaklarımızın server’larında saklanmak amacıyla açık rızanız ile yurt dışına aktaracaktır. Bu işleyiş sizin için uygunsa, gönder tuşuna basarak açık rızanızı belirtmiş olursunuz.
                                      </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PersonalDataModal;