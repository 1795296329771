import React, {Component} from 'react';
import { connect } from 'react-redux';

class StockErrorLbComponent extends Component {

    lbCon= React.createRef();

    outsideClick=(e)=>{
        if(this.lbCon.current && !this.lbCon.current.contains(e.target)){
            this.props.closeLightBox()
        }
    }
    componentWillUnmount() {
        if (typeof window !== "undefined"){
            document.body.classList.remove('no-scroll');
        }

    }

    render() {
        let{closeLightBox}=this.props
        return (
            <div className="payment-err-lb-root" onClick={(e)=>this.outsideClick(e)}>
                <div className="lb-container" ref={this.lbCon}>
                    <h3>Ödeme hatası</h3>
                    <p>Seçtiğiniz modelde stok bulunamadı.</p>
                    <div className="btn-block">
                        <button onClick={closeLightBox} className="red-btn-item" >TAMAM</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(StockErrorLbComponent);