import { GET_MODEL_COLOR, GET_SELECTED_COLOR, GET_USER_VERSION_DETAIL, RECEIVE_USER_SELECTION, RECEIVE_TRANSACTION_INFO } from "../constants/actionTypes";

const initialStates = { selectedColor: null }
const userSelectionReducer = (state = initialStates, action) => {
   switch (action.type) {
      case GET_USER_VERSION_DETAIL:
         return {
            ...state,
            version_detail: action.payload
         }
      case GET_MODEL_COLOR:
         return {
            ...state,
            model_color: action.payload
         }
      case GET_SELECTED_COLOR:
         return {
            ...state,
            selectedColor: action.payload
         }
      case RECEIVE_USER_SELECTION:
         return {
            ...state,
            selectedColor: action.payload.selectedColor
         }
      case RECEIVE_TRANSACTION_INFO:
         return {
            ...state,
            selectedColor: action.payload.vehicleDetail.selectedModel.colors[0]
         }
      default:
         return state
   }
}
export default userSelectionReducer;
