import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/main.css';
import App from './App';
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { loadableReady } from '@loadable/component'
const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());
}
let store = createStore(reducers, window.initialStoreData ? window.initialStoreData : {}, applyMiddleware(...middleware));


if (typeof window !== "undefined") {
    const loadComp = async () => {
        loadableReady(() => {
            ReactDOM.hydrate(
                <Provider store={store}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Provider>,
                document.getElementById('root')
            );
        })
    }
    loadComp();
}