import async from "async";
import * as WebService from '../services/webService';
import {receiveModels} from "../actions/modelActions";


export const getModelsDataInit = (dispatch) => {
    return new Promise((resolve,failure)=>{
        async.parallel([
            (cb) => {
                WebService.getVehicles()
                    .then((res) => {
                        if (res.data.success) {
                            cb(null,res.data.success);

                        }
                    })
                    .catch((err) => {
                        cb(null,false);

                    })
            }
        ],(err,result)=>{
            dispatch(receiveModels(result[0]));
            resolve(result);
        });
    });
};
