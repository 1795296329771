import * as ROUTES from '../constants/routePaths';
import {getModelsDataInit} from './modelsPageService';
import { getPersonalFormDataInit } from './personalFormPageService';


export default class PageService {
   currentPage = ROUTES.SELECT_CAR_PAGE;

   constructor(url) {
      this.currentPage = url;
   }

   serviceData = (dispatch, urlParams, resolve) => {
      switch (this.currentPage.toLocaleLowerCase("en")) {
         case ROUTES.HOME_PAGE:
         case ROUTES.LEGAL_WARNING:
         case ROUTES.WHY_ONLINE_PAGE:
         case ROUTES.PAYMENT_RESULT:
         case ROUTES.PAYMENT_STAGE:
         case ROUTES.SUMMARY_INFO:
            resolve(true);
            // break;
         case ROUTES.SELECT_CAR_PAGE:
         case ROUTES.SELECT_VERSION_WITHOUT_PARAM + (urlParams && urlParams.params && urlParams.params.modelLink ? urlParams.params.modelLink : ``):
            if(typeof window !=="undefined"){
               getModelsDataInit(dispatch).then((res) => {
                  resolve(res);
               })
            }else{
               resolve(true)
            }
            break;
         case ROUTES.SELECT_DESIGN_WITHOUT_PARAM +(urlParams && urlParams.params && urlParams.params.detail ? urlParams.params.detail :``):
         // case ROUTES.PERSONAL_INFO_WITHOUT_PARAM +(urlParams && urlParams.params && urlParams.params.detail ? urlParams.params.detail :``):
            // getVehicleDetailInit(dispatch,urlParams.params.detail).then(res=>{
            //    resolve(res);
            // })
            getPersonalFormDataInit(dispatch,urlParams.params.detail).then(res=>{
               resolve(res);
            })
            break;
         case `${ROUTES.PERSONAL_INFO_WITHOUT_PARAM}${(urlParams && urlParams.params && urlParams.params.detail ? urlParams.params.detail :``)}`:

            getPersonalFormDataInit(dispatch,urlParams.params.detail).then(res=>{
                  resolve(res);
               })
         case '':
            resolve(false);
            break;
         default:
            resolve(false);
            break;

      }
   }

   getData = (dispatch, urlParams) => {
      return new Promise((resolve, reject) => {
         this.serviceData(dispatch, urlParams, resolve);
      });
   }
}
