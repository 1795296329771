import {
    DETAIL_INFO_DATA,
    GET_VEHICLE_DETAIL,
    PERSONAL_INFO_DATA,
    SAVE_TRANSACTION,
    EMPTY_TRANSACTION,
    GET_BREADCRUMB_LINK,
    RECEIVE_TRANSACTION_INFO, FORM_DIRTY_VALUE, HTML_HEIGHT, IS_BC_OPEN
} from "../constants/actionTypes";


const initialValues = {isDirty:false,vehicleDetailData:{}}
const commonReducer = (state = initialValues, action) => {
    switch (action.type) {
        case PERSONAL_INFO_DATA:
            return {
                ...state,
                personalInfoFormData: action.payload
            }
        case DETAIL_INFO_DATA:
            return {
                ...state,
                detailInfoFormData: action.payload
            }
        case GET_VEHICLE_DETAIL:
            return {
                ...state,
                vehicleDetailData:{...state.vehicleDetailData,...action.payload}
            }
        case SAVE_TRANSACTION:
            return {
                ...state,
                transactionId: action.payload
            }
        case EMPTY_TRANSACTION:
            return {
                ...state,
                transactionId: null
            }
        case GET_BREADCRUMB_LINK:
            return{
                ...state,
                breadCrumbLinks:[...action.payload]
            }
        case RECEIVE_TRANSACTION_INFO:
            return {
                ...state,
                personalInfoFormData: action.payload.personalInfo,
                transactionId: action.payload.transactionId
            }
        case FORM_DIRTY_VALUE:
            return{
                ...state,
                isDirty:action.payload
            }
        case HTML_HEIGHT:
            return{
                ...state,
                htmlHeight:action.payload
            }
        case IS_BC_OPEN:
            return{
                ...state,
                isBcOpen:action.payload
            }
        default:
            return state;


    }
}
export default commonReducer;
