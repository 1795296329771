import React, {Component} from 'react';

class TextAreaComponent extends Component {
    render() {
        const {input,label,placeholder,meta,err_txt}= this.props
        return (
            <div className={"input-item " + (meta.touched && meta.error ? "error" : "")}>
                <label htmlFor="">{label}</label>
                <textarea {...input} placeholder={placeholder} data-name={input.name}/>
                <div className="error-text">
                    <span>{err_txt}</span>
                </div>
            </div>
        );
    }
}

export default TextAreaComponent;
