import React, {Component} from 'react';
import {Field, Form} from "react-final-form";
import TextInputComponent from "../formFields/textInputComponent";
import {resendVerification, smsVerification} from '../../services/webService';
import LoaderComponent from '../common/loaderComponent';
import {saveTransactionIDAction} from '../../actions/personelActions';
import {SUMMARY_INFO} from '../../constants/routePaths';
import {connect} from 'react-redux';
import {getPersonalInfoFormData} from '../../actions';
import {checkNumber} from "../../helper";


const validate = (values) => {
   const errors = {};
   if (!values.verification_code || (values.verification_code && values.verification_code.length !== 6)) {
      errors.verification_code = "Kodu giriniz ";
   }

   return errors;

};

class SmsLightBoxComponents extends Component {
   state = {
      isLoaderShowing: false,
      errorExist: false,
      timeEnd: false,
      time: {
         minutes: null,
         seconds: null,
      },
      duration:  60* 1000,
      startTime: Date.now()
   }
   timeCount;
   offset = 100;
   timer;

   componentDidMount() {
      this.startTime()

   }

   msToTime = (duration) => {
      let seconds = Math.floor((duration / 1000) % 60);
      let minutes = Math.floor((duration / (1000 * 60)) % 60);
      minutes = minutes.toString().padStart(2, '0');
      seconds = seconds.toString().padStart(2, '0');
      return {
         minutes,
         seconds
      };
   }
   startTime = () => {
      this.setState({
             startTime: Date.now(),

          }, () => {
             clearInterval(this.timeCount)
             this.timeCount = setInterval(this.runTimer, 1000)
             setTimeout(() => {
                this.setState({
                   timeEnd: true
                })
             }, this.state.duration)
          }
      )

   }
   restartTime = () => {
      this.startTime()
      setTimeout(() => {
         this.setState({
            timeEnd: false,
            resendSms:true
         },()=>{
            resendVerification({
               transaction_id: this.props.transaction_id
            }).then(res=>{
               if(res.data.succes){

               }
            }).catch((err)=>{
               console.log("err");
            })
         })

      }, 100)

      // this.verifySms()

   }
   verifySms = (values) => {
      smsVerification({
         verification_code: values.verification_code,
         transaction_id: this.props.transaction_id
      }).then((result) => {

         if (result.data.succes) {
            this.setState({
               isLoaderShowing: false
            })
            this.props.dispatch(getPersonalInfoFormData(this.props.personData))
            this.props.dispatch(saveTransactionIDAction(result.data.transaction_id))

            this.props.history.push(SUMMARY_INFO)
         } else {
            this.setState({
               isLoaderShowing: false,
               errorExist: true
            })
            this.setTimer();
         }
      }).catch(() => {
         this.setState({
            isLoaderShowing: false,
            errorExist: true
         })
         this.setTimer();
      })
   }

/*   resendVerifySms=()=>{
      resendVerification({
         transaction_id: this.props.transaction_id
      }).then((result) => {
         if (result.data.succes) {
            this.setState({
               isLoaderShowing: false
            })
            this.props.dispatch(getPersonalInfoFormData(this.props.personData))
            this.props.dispatch(saveTransactionIDAction(result.data.transaction_id))
            this.props.history.push(SUMMARY_INFO)
         } else {
            this.setState({
               isLoaderShowing: false,
               errorExist: true
            })
            this.setTimer();
         }
      }).catch(() => {
         this.setState({
            isLoaderShowing: false,
            errorExist: true
         })
         this.setTimer();
      })
   }*/
   runTimer = () => {
      const diff = Date.now() - this.state.startTime;
      let remaining = (this.state.duration - diff) + this.offset;
      if (remaining < 0) {
         remaining = 0;
      }
      this.setState(() => ({
         time: this.msToTime(remaining)

      }));
      if (remaining === 0) {
         clearInterval(this.timeCount)
         this.timeCount = null;
      }

   }
   submit = (values) => {
      this.setState({isLoaderShowing: true})
      this.verifySms(values)


   }
   setTimer = () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.setState({
            errorExist: false
         })
      }, 1000)
   }
   componentWillUnmount() {
      clearInterval(this.timeCount)
   }

   render() {
      let {closeSMSModal} = this.props;
      return (
          <div className="sms-lb-root">
             <div className="sms-lb-container">
                <h3>SMS Onay Kodu</h3>
                <p>Cep telefonunuza gelen kısa mesajdaki onay kodunu yazın.</p>
                <Form onSubmit={this.submit}
                      validate={validate}
                      render={({handleSubmit,values, form}) => {
                         return(
                             <form onSubmit={handleSubmit} id="smsCode">
                                <Field
                                    name={"verification_code"}
                                    component={TextInputComponent}
                                    err_txt={"Kodu giriniz "}
                                    parse={checkNumber}
                                    maxLength={6}
                                />
                             </form>
                         )
                      }}>
                </Form>
                <p className="time">
                   {
                      this.state.time.seconds &&
                      <span className={` ${this.state.timeEnd ? "end" : ""}`}>
                        {this.state.time.minutes}:{this.state.time.seconds}</span>
                   }
                </p>


                {
                   this.state.errorExist &&
                   <div className="error-text">
                      <span>Hatalı Kod</span>
                   </div>
                }
                <div className="btn-block">
                   <button type={"button"} onClick={() => {
                      closeSMSModal()
                      clearInterval(this.timeCount)
                   }} className="stroke-btn">VAZGEÇ
                   </button>
                   {
                      this.state.timeEnd ?
                          <button className="red-btn-item new-code" onClick={this.restartTime}>YENİ KOD İSTE</button>
                          :
                          <button type={"submit"} form="smsCode" className="red-btn-item ">GÖNDER</button>

                   }
                </div>
             </div>
             {
                this.state.isLoaderShowing && <LoaderComponent/>
             }
          </div>


      );
   }
}

const mapStateToProps = state => ({
   selectedModel: state.personelReducer.selectedModel || null,
})
export default connect(mapStateToProps)(SmsLightBoxComponents);
