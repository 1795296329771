export const PERSONAL_INFO_DATA = "PERSONAL_INFO_DATA"
export const DETAIL_INFO_DATA = "DETAIL_INFO_DATA"
export const RECEIVE_MODELS = "RECEIVE_MODELS"
export const GET_VEHICLE_DETAIL = "GET_VEHICLE_DETAIL"
export const GET_USER_VERSION_DETAIL = "GET_USER_VERSION_DETAIL"
export const GET_MODEL_COLOR = "GET_MODEL_COLOR"
export const GET_PERSONEL_DATA ="GET_PERSONEL_DATA"
export const SAVE_TRANSACTION ="SAVE_TRANSACTION"
export const RECEVIVE_DEALERS_BY_MODEL ="RECEVIVE_DEALERS_BY_MODEL"
export const EMPTY_TRANSACTION = "EMPTY_TRANSACTION";
export const GET_BREADCRUMB_LINK="GET_BREADCRUMB_LINK"
export const GET_SELECTED_COLOR="GET_SELECTED_COLOR"
export const RECEIVE_USER_SELECTION="RECEIVE_USER_SELECTION"
export const RECEIVE_TRANSACTION_INFO="RECEIVE_TRANSACTION_INFO"
export const FORM_DIRTY_VALUE="FORM_DIRTY_VALUE"
export const HTML_HEIGHT="HTML_HEIGHT"
export const IS_BC_OPEN="IS_BC_OPEN"
