import React, {Component} from 'react';
import { connect } from 'react-redux';
import LoaderComponent from '../common/loaderComponent';

class ThreeDSecureLbComponent extends Component {

    lbCon= React.createRef();
    state={
        isloaderShowing:true
    }
    outsideClick=(e)=>{
        if(this.lbCon.current && !this.lbCon.current.contains(e.target)){
            this.props.closeLightBox()
        }
    }
    componentWillUnmount() {
        if (typeof window !== "undefined"){
            document.body.classList.remove('no-scroll');
        }

    }

    iframeLoaded = () => {
        this.setState({
            isloaderShowing:false
        })
    }

    render() {
        let{data}=this.props
        return (
            <div className="payment-secure-lb-root" onClick={(e)=>this.outsideClick(e)}>
                <div className="lb-wrapper">
                    {
                        this.state.isloaderShowing && <LoaderComponent/>
                    }
                    <iframe onLoad={this.iframeLoaded} className="lb-container" ref={this.lbCon} srcDoc={data.substring(data.indexOf("<html"))} ></iframe>
                </div>

            </div>
        );
    }
}

export default connect()(ThreeDSecureLbComponent);