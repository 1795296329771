export const HOME_PAGE = "/nasil-alirim";
export const SELECT_CAR_PAGE="/"
export const SELECT_VERSION="/versiyon/:modelLink"
export const SELECT_VERSION_WITHOUT_PARAM="/versiyon/"
export const SELECT_DESIGN_WITHOUT_PARAM="/model-detay/"
/*todo:params sorulacak */
export const SELECT_DESIGN="/model-detay/:detail"
export const PERSONAL_INFO="/kisisel-bilgiler/:detail"
export const PERSONAL_INFO_WITHOUT_PARAM="/kisisel-bilgiler/"
export const SUMMARY_INFO="/ozet"
export const CONTRACT="/sozlesme/:transactionId"

export const PAYMENT_STAGE="/odeme/:transactionId?"
export const PAYMENT_STAGE_WITHOUT_PARAM="/odeme"
export const PAYMENT_RESULT_WITHOUT_PARAM="/odeme-sonuc"
export const PAYMENT_RESULT="/odeme-sonuc/:transactionId"
export const WHY_ONLINE_PAGE="/neden-online"
export const LEGAL_WARNING="/yasal-uyari"
export const KVKK_PAGE="/kisisel-verilerin-korunmasi"

