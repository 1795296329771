import React, {Component} from 'react';
import LazyLoad from "react-lazyload";
import {ASSETS} from '../constants/paths';
import {changeDocumentMetas} from "../helper";

class HomePage extends Component {
    titleValue="Satın alma yöntemi | Honda Online";
    titleDesc="Honda’nızı dilediğiniz yerden, sadece 5 adımda nasıl satın alacağınızı öğrenin.";
    constructor() {
        super();
        changeDocumentMetas(this.titleValue,this.titleDesc)
    }



    render() {

        return (
            <div className="home-root ">
                <div className="title-container">
                    <h1>Satın alma yöntemi</h1>
                </div>
                <div className="double-content-container">
                    <div className="content-block">
                        <div className="text-block">
                            <div className="number-block">
                                <span>1</span>
                            </div>
                            <div className="explain-block">
                                <h3>Honda modelinizi seçin</h3>
                                <p>Size uygun model seçeneklerine göz atın.</p>
                            </div>
                        </div>
                        <div className="image-block">
                            <LazyLoad>
                                <picture>
                                    <source media="(min-width: 769px)"
                                            srcSet={`${ASSETS + "/img/z-rv/nasil-alirim-desktop.webp"} 1x, ${ASSETS + "/img/z-rv/nasil-alirim-desktop_2x.webp"} 2x `}
                                            type="image/webp">
                                    </source>
                                    <source media="(min-width: 769px)"
                                            srcSet={`${ASSETS + "/img/z-rv/nasil-alirim-desktop.jpg"} 1x, ${ASSETS + "/img/z-rv/nasil-alirim-desktop_2x.jpg"} 2x `}
                                            type="image/jpeg">
                                    </source>
                                     <img  src={ASSETS + "/img/z-rv/nasil-alirim-desktop.jpg"} alt="Honda modelinizi seçin"/>
                                </picture>
                            </LazyLoad>
                        </div>
                    </div>
                    {/* <div className="content-block">
                        <div className="image-block">
                            <LazyLoad>
                                <picture>
                                    <source
                                        media="(min-width: 769px)"
                                        srcSet={`${ASSETS + "/img/z-rv/zrv_opsiyon_sec_desktop.webp"} 1x, ${ASSETS + "/img/z-rv/zrv_opsiyon_sec_desktop_2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        media="(min-width: 769px)"
                                        srcSet={`${ASSETS + "/img/z-rv/zrv_opsiyon_sec_desktop.jpg"} 1x, ${ASSETS + "/img/z-rv/zrv_opsiyon_sec_desktop_2x.jpg"} 2x `}
                                        type="image/jpeg"/>
                                    <source
                                        media="(max-width: 768px)"
                                        srcSet={`${ASSETS + "/img/z-rv/zrv_opsiyon_sec_mobile.webp"} 1x, ${ASSETS + "/img/z-rv/zrv_opsiyon_sec_mobile_2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        media="(max-width: 768px)"
                                        srcSet={`${ASSETS + "/img/z-rv/zrv_opsiyon_sec_mobile.jpg"} 1x, ${ASSETS + "/img/z-rv/zrv_opsiyon_sec_mobile_2x.jpg"} 2x `}
                                        type="image/jpeg"/>
                                    <img  src={ASSETS + "/img/z-rv/zrv_opsiyon_sec_desktop.jpg"} alt="Size En Uygun Versiyonu Bulun"/>
                                </picture>
                            </LazyLoad>
                        </div>
                        <div className="text-block">
                            <div className="number-block">
                                <span>2</span>
                            </div>
                            <div className="explain-block">
                                <h3>Size En Uygun Versiyonu Bulun</h3>
                                <p>Honda otomobilinizin motor, versiyon, model yılı, renk gibi özelliklerini seçin. </p>
                            </div>
                        </div>
                    </div> */}
                    <div className="content-block">
                        <div className="text-block">
                            <div className="number-block">
                                <span>2</span>
                            </div>
                            <div className="explain-block">
                                <h3>Formu doldurun ve bayi seçimini yapın</h3>
                                <p>Kişisel bilgilerinizi içeren formu doldurup aracınızı seçeceğiniz bayiden teslim alabilirsiniz.</p>
                            </div>
                        </div>
                        <div className="image-block">
                            <LazyLoad>
                                <picture>
                                    <source
                                        srcSet={`${ASSETS + "/img/homepage-image-3.webp"} 1x, ${ASSETS + "/img/homepage-image-3_2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        srcSet={`${ASSETS + "/img/homepage-image-3.png"} 1x, ${ASSETS + "/img/homepage-image-3_2x.png"} 2x `}
                                        type="image/jpeg"/>
                                    <img  src={ASSETS + "/img/homepage-image-3.png"} alt="Formu Doldurun"/>
                                </picture>
                            </LazyLoad>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="image-block">
                            <LazyLoad>
                                <picture>
                                    <source
                                        media="(min-width: 769px)"
                                        srcSet={`${ASSETS + "/img/z-rv/tesekkur-desktop.webp"} 1x, ${ASSETS + "/img/z-rv/tesekkur-desktop-2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        media="(min-width: 769px)"
                                        srcSet={`${ASSETS + "/img/z-rv/tesekkur-desktop.jpg"} 1x, ${ASSETS + "/img/z-rv/tesekkur-desktop-2x.jpg"} 2x `}
                                        type="image/jpeg"/>
                                    <source
                                        media="(max-width: 768px)"
                                        srcSet={`${ASSETS + "/img/z-rv/tesekkur-mobile.webp"} 1x, ${ASSETS + "/img/z-rv/tesekkur-mobile-2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        media="(max-width: 768px)"
                                        srcSet={`${ASSETS + "/img/z-rv/tesekkur-mobile.jpg"} 1x, ${ASSETS + "/img/z-rv/tesekkur-mobile-2x.jpg"} 2x `}
                                        type="image/jpeg"/>
                                    <img  src={ASSETS + "/img/z-rv/tesekkur-desktop.jpg"} alt="Ön ödemenizi yapın"/>
                                </picture>
                            </LazyLoad>
                        </div>
                        <div className="text-block">
                            <div className="number-block">
                                <span>3</span>
                            </div>
                            <div className="explain-block">
                                <h3>Ön ödemenizi yapın</h3>
                                <p>50.000 TL ödeme yaparak rezervasyonu tamamlayın. Kısa süre içerisinde bayimiz sizinle iletişime geçecektir.</p>
                            </div>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="text-block">
                            <div className="number-block">
                                <span>4</span>
                            </div>
                            <div className="explain-block">
                                <h3>Aracınızı bayiden teslim alın</h3>
                                <p>Ödemeyi tamamlayıp, yetkili ile konuştuktan sonra seçtiğiniz bayiden aracınızı teslim alın.</p>
                            </div>
                        </div>
                        <div className="image-block">
                            <LazyLoad>
                                <picture>
                                    <source
                                        srcSet={`${ASSETS + "/img/homepage-image-5.webp"} 1x, ${ASSETS + "/img/homepage-image-5_2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        srcSet={`${ASSETS + "/img/homepage-image-5.png"} 1x, ${ASSETS + "/img/homepage-image-5_2x.png"} 2x `}
                                        type="image/jpeg"/>
                                    <img  src={ASSETS + "/img/homepage-image-5.png"} alt="Bayiden Teslim Alın"/>
                                </picture>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
                <div className="bottom-text-area">
                    <div className="head-text-block">
                        <h2>Rezervasyonu nasıl iptal edebilirim?</h2>
                    </div>
                    <div className="text-block">
                        <p>Rezervasyonu iptali için herhangi bir işlem yapılmasına gerek yoktur. 3 gün içinde yetkili satıcı tarafından satış işlemi gerçekleşmeyen tüm rezervasyonlar otomatik olarak iptal edilir ve rezervasyon bedeli geri ödenir.
</p>
                    </div>

                </div>
            </div>
        );
    }
}

export default HomePage;