import React, {Component} from 'react';
import {connect} from "react-redux";
import {getSelectedColor} from "../../actions";
import {SELECT_DESIGN_WITHOUT_PARAM} from "../../constants/routePaths";

class CustomColorSelectComponent extends Component {
   setColors=(colorGroup)=>{

      if(this.props.colors.length>1 && (this.props.selectedColor.id !== colorGroup.id || this.props.selectedColor.name !== colorGroup.name)){
         this.props.dispatch(getSelectedColor(colorGroup))
         // this.props.history.push(SELECT_DESIGN_WITHOUT_PARAM+colorGroup.link)
         this.props.history.replace(SELECT_DESIGN_WITHOUT_PARAM+colorGroup.link)
         this.props.resetVal()
         if (this.props.selectedColor && this.props.selectedColor.id === colorGroup.id){

         }
      }
      
      //console.log("colorGroup",this.props.history.location.pathname.replace(SELECT_DESIGN_WITHOUT_PARAM,colorGroup.link));
   }
   


   render() {
      let {colors,selectedColor} = this.props
      return (
          <div className="select-colour-block">

             {
                colors && colors.map((clr, index) =>

                    <div className={`select-colour-item ${clr.detail && clr.name === selectedColor.name && (selectedColor.detail.color_code ==="#FFFFFF" || selectedColor.detail.color_code ==="#F5F1F1") ? "second-option":""} ${clr.name && clr.name === selectedColor.name ? "active" : ""} ${colors.length<2 ? "default":""}`}
                    style={clr.detail && (clr.detail.color_code === "#FFFFFF" || clr.detail.color_code === "#F5F1F1") ? {borderColor: "rgba(0,0,0, .1)"}:{borderColor: clr.detail.color_code}} key={index}
                         onClick={() =>{
                            this.setColors(clr)

                         }}>
                       <div style={{boxShadow: clr.detail && (clr.detail.color_code === "#FFFFFF" || clr.detail.color_code === "#F5F1F1") ? "0px 0px 10px rgba(0,0,0, 0.2)" : "",backgroundColor:`${clr.detail?clr.detail.color_code:"#FFFF"}`}} className="colour"/>
                    </div>
                )
             }
          </div>
      );
   }
}
const mapStateToProps=state=>({
   modelDetail:state.commonReducer.vehicleDetailData ? state.commonReducer.vehicleDetailData.modelDetails : {},
   selectedColor: state.userSelectionReducer.selectedColor
})
export default connect(mapStateToProps) (CustomColorSelectComponent);
