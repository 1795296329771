import React, {Component} from "react";
import RoutesComponent from "./components/routesComponent";

class App extends Component{

  render() {
    return (
        <main>
          <RoutesComponent/>
        </main>
    );
  }
}


export default App;
