import React, {Component} from 'react';
import {connect} from 'react-redux';
import PageService from "../pageServices/pageService";
import ChangeLightBoxComponent from "./lightBox/changeLightBoxComponent";
import {getElementWidth} from "../helper";
import {isBcOpen} from "../actions";

class BreadCrumpComponent extends Component {
   constructor(props) {
      super(props);
      this.pageService = new PageService(props.location.pathname);
      this.selectedModel = props.models.find(model => (model.link))
   }
   listItem=React.createRef();
   breadBlock=React.createRef();
   pageService;
   selectedModel;

   state = {
      isSticky: false,
      selectedLink:null,
      isOpen:false
   }
   setTranslateValue=()=>{
      if(this.listItem.current && window.innerWidth<641){
         Array.from(this.listItem.current.children).forEach((li,index)=>{
            if(li.children[0].classList.contains("active")){
               let elOffsetLeft = (li.offsetLeft+getElementWidth(li))+5
               let translateValue = elOffsetLeft-this.breadBlock.current.offsetWidth
               if(translateValue>0){
                  this.listItem.current.style.transform = `translateX(-${translateValue}px)`
                  this.listItem.current.style.overflow = `visible`
               }
               else{
                  this.listItem.current.style.transform = `translateX(0px)`
                  this.listItem.current.style.overflow = `auto`
               }
            }
         })
      }
   }

   componentDidMount() {
      if(typeof  window !== "undefined") {
         window.addEventListener("scroll", this.addStickyToHeader)
         window.addEventListener("resize", this.setTranslateValue)
      }
      /*todo:timeout sorulacak {emre}*/
      setTimeout(()=>{
         this.setTranslateValue()
      },300)
   }
   addStickyToHeader = () => {
      if (typeof window!=="undefined" && window.scrollY > 0) {
         this.setState({
            isSticky: true
         })
      } else {
         this.setState({
            isSticky: false
         })
      }
   }

   handleChange =item=> {
      let index = this.props.breadCrumbData.indexOf(item)
      this.setState({
         selectedLink:item
      })

      if(index<3 && this.props.isDirty){
         this.setState({
            isOpen:true
         })
         this.props.dispatch(isBcOpen(true))
         return;
      }
      if(item.link){
         this.props.history.replace(item.link)
      }
   }
   backward=()=>{
      this.setState({
         isOpen:false
      })
      this.props.dispatch(isBcOpen(false))
   }
   componentDidUpdate(prevProps) {
      if((prevProps.breadCrumbData !== this.props.breadCrumbData)){
        setTimeout(this.setTranslateValue,500)
      }
      if(prevProps.location.pathname !== this.props.location.pathname && this.state.selectedLink){
         this.backward()
      }
   }
   componentWillUnmount() {
      window.removeEventListener("scroll", this.addStickyToHeader)
      window.removeEventListener("resize", this.setTranslateValue)
   }


   render() {
      let {breadCrumbData} = this.props;
      return (
         (breadCrumbData && breadCrumbData.length>0) ?
          <div className={`breadcrumb-root ${this.state.isSticky ? "sticky" : ""}`}>
            {/* {
               ( this.state.selectedLink && this.state.selectedLink.link !== null) &&
                <ChangeLightBoxComponent backward={this.backward} index={this.state.index} selectedLink={this.state.selectedLink}/>
             }*/}
             {/*todo: alttaki kosul kontrol edilecek!!*/}
             {
              (this.state.isOpen && this.props.isDirty) &&
                <ChangeLightBoxComponent  backward={this.backward} index={this.state.index} backLink={this.state.selectedLink&&this.state.selectedLink.link}/>
             }
             <div className="breadcrumb-container">
               <div className="breadcrumb-block" ref={this.breadBlock}>
                  <ul ref={this.listItem}>
                     {
                        breadCrumbData.map((item,index,arr)=>
                            <li key={index}>
                              {/* {
                                  (item.link || item.url) ?
                                      <Link className={item.isActive ? "active":""} to={item.link || item.url}>{item.name}</Link>
                                      :<p className={item.isActive ? "active":""} onClick={()=>this.handleChange(item)}>{item.name}</p>
                               }*/}
                               {
                                  <p style={item.link ? {cursor:"pointer"}:{}} className={item.isActive ? "active":""} onClick={()=>this.handleChange(item)}>{item.name}</p>
                               }
                            </li>
                        )
                     }
                  </ul>
               </div>
             </div>
          </div>
          :
          null
      );
   }
}

const mapStateToProps = (state) => ({
   selectedModels: state.userSelectionReducer.car_model || [],
   models: state.modelsReducer.models || [],
   breadCrumbData:state.commonReducer.breadCrumbLinks || [],
   isDirty:state.commonReducer.isDirty
})

export default connect(mapStateToProps)(BreadCrumpComponent);
