import React, {Component} from 'react';
import {connect} from "react-redux";

class SelectBoxComponent extends Component {
   clickSpace = React.createRef();
   state={
      isOpen:null,
      selectedItem:null
   }
   toggleMenu = () => {
      this.setState({
         isOpen: !this.state.isOpen
      })
   }
   componentDidMount() {
      if (typeof window !== "undefined") {
         window.addEventListener("click", this.windowClicked)
      }
   }
   windowClicked = (e) => {
      if (this.clickSpace.current && !this.clickSpace.current.contains(e.target) && this.state.isOpen) {
         this.setState({
            isOpen: false
         })
      }
   }
   handleClick = (item) => {
      this.setState({
         selectedItem:item
      },()=>{

      })
   }
  componentWillUnmount() {
     if (typeof window !== "undefined") {
        window.removeEventListener("click", this.windowClicked)
     }
  }

   render() {
      let {options,input,label,meta:{error,touched},onChangeFunc,err_txt} = this.props
      return (
          <div className={`detail-block ${touched && error ? "error":""}`}
               ref={this.clickSpace}>
             <div className="name-item">
                <p>{label}</p>
             </div>
             {
                options &&
                <div className={`select-block`} data-name={input.name}>
                   <div className={`select-item ${this.state.isOpen &&  options.length > 0 ? "opened" : ""} `} onClick={() => this.toggleMenu()}>
                      <p>{input.value ? input.value.label : null} </p>

                         <div className="open-select-menu">
                            {
                                <div className="open-select-container">
                                   {
                                      options.map((item, key) =>
                                          <div className="menu-item" key={key}  onClick={()=>{
                                             this.handleClick(item)
                                             input.onChange(item)
                                             onChangeFunc && onChangeFunc()
                                          }}>
                                             <p>{item.label}</p>
                                          </div>
                                      )
                                   }
                                </div>
                            }
                         </div>

                      {
                         options.length > 0 &&
                         <i className="gg-chevron"/>
                      }
                   </div>
                   <div className="error-text">
                      <span>{err_txt}</span>
                   </div>
                </div>
             }

          </div>
      );
   }
}
const mapStateToProps=state=>({
   personalData:state.commonReducer.personalInfoFormData || {}
})
export default connect(mapStateToProps) (SelectBoxComponent);
