import async from "async";
import * as WebService from '../services/webService';
import { receiveDealersByModel } from "../actions/modelActions";
import { getVehicleDetailData } from "../actions";


export const getPersonalFormDataInit = (dispatch, params) => {
    return new Promise((resolve, failure) => {
        async.parallel([
            (cb) => {
                WebService.getVehicleDetail(params)
                    .then((res) => {
                        if (res.data.success) {
                            cb(null, res.data.success);

                        } else {
                            cb(null, false )

                        }
                    })
                    .catch((err) => {
                        cb(null, false);

                    })
            },
            (cb) => {
                WebService.getVehicleDealers(params)
                    .then((res) => {
                        if (res.data.success) {
                            cb(null, res.data.success);

                        } else {
                            cb(null,false)

                        }
                    })
                    .catch((err) => {
                        cb(null, false);

                    })
            }
        ], (err, result) => {
            dispatch(getVehicleDetailData({ [params]: result[0] }));
            if(result[1]){
                dispatch(receiveDealersByModel(result[1]));
            }
            resolve(result);
        });
    });
};
