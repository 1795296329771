import React, {Component} from 'react';
import LazyLoad from "react-lazyload";
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";

class SliderImgComponent extends Component {
    render() {
        let {img,selectedColor,model}=this.props
        return (
            <div className="slider-item ">
                <div className="img-item">
                    <LazyLoad>
                        <picture>
                            <source
                                srcSet={`${ASSETS}${img.substring(0, img.lastIndexOf("."))}.webp 1x, ${ASSETS}${img.substring(0, img.lastIndexOf("."))}_2x.webp 2x`}/>
                            <source
                                srcSet={`${ASSETS}${img} 1x, ${ASSETS}${img.substring(0, img.lastIndexOf("."))}_2x${img.substring(img.lastIndexOf("."))} 2x`}/>
                            <img src={`${ASSETS}${img}`} alt={`${model}-${selectedColor.name}`}/>
                        </picture>
                    </LazyLoad>
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state, ownProps) => ({
    selectedColor: state.userSelectionReducer.selectedColor
})
export default connect(mapStateToProps) (SliderImgComponent);
