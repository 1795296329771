import React, {Component} from "react";
import {ASSETS} from "../../constants/paths";

class LoaderComponent extends Component{
    render(){
        return(
            <div className="loader-root">
                <video autoPlay loop muted playsInline>
                    <source
                       src={ASSETS + "/img/loader.webm"}
                        type="video/webm"/>
                    <source
                        src={ASSETS +  "/img/loader.mp4"}
                        type="video/mp4"/>
                </video>
            </div>
        )
    }
}

export default LoaderComponent;